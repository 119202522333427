import React from "react";
import Header from "../components/shared/Header";
import { FaUser, FaEye } from "react-icons/fa";
import { FaEarthAfrica } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useAppContext } from "../components/context/Context";
import { useTranslation } from "react-i18next";
const AccountSettings = () => {
  const { t } = useTranslation();
  const { theme } = useAppContext();
  return (
    <div className='h-[100svh] dark:bg-[#15292B]'>
      <Header path={"/profile"} />
      <div className='py-6 flex px-5 pt-8  dark:bg-[#15292B]'>
        <div className='flex flex-col gap-10 px-5'>
          <Link to={"/account/settings"} className='flex items-center gap-5'>
            <FaUser size={25} color={theme === "light" ? "#000" : "#ADBDDB"} />
            <div>
              <h4 className='font-bold text-[14px] dark:text-[#ADBDDB] '>
                {t("Account Information")}
              </h4>
              <p className='text-[12px] dark:text-[#ADBDDB]'>
                {t("Edit your account information")}
              </p>
            </div>
          </Link>
          <Link className='flex items-center gap-5' to={"/account/language"}>
            <FaEarthAfrica
              size={25}
              color={theme === "light" ? "#000" : "#ADBDDB"}
            />
            <div>
              <h4 className='font-bold text-[14px] dark:text-[#ADBDDB] '>
                {t("Languages")}
              </h4>
              <p className='text-[12px] dark:text-[#ADBDDB]'>
                {t("Change your Language")}
              </p>
            </div>
          </Link>
          <Link className='flex items-center gap-5' to={"/changePassword"}>
            <FaEye size={25} color={theme === "light" ? "#000" : "#ADBDDB"} />
            <div>
              <h4 className='font-bold text-[14px] dark:text-[#ADBDDB]'>
                {t("Change Password")}
              </h4>
              <p className='text-[12px] dark:text-[#ADBDDB]'>
                {t("Change your password")}
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
