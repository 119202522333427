import React from "react";
import { ConfigProvider, Drawer } from "antd";
import "./drawer.css";
import { useAppContext } from "./context/Context";
const CustomDrawer = ({ open, onClose, children }) => {
  const { theme } = useAppContext();

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgElevated: theme === "light" ? "#fff" : "#15292B",
          },
        }}>
        <Drawer
          placement={"bottom"}
          closable={false}
          onClose={onClose}
          open={open}
          key={"bottom"}
          // mask={false}
          height={"auto"}
          style={{ padding: 0 }}>
          {children}
        </Drawer>
      </ConfigProvider>
    </>
  );
};
export default CustomDrawer;
