import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { confirmEmployeeDropHistoryAPI, declineEmployeeDropHistoryAPI, getEmployeeDropHistoryAPI } from "../lib/apis";
import { toast } from "../lib/toastFun";

const Dropout = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [disableConfirmBtn, setDisableConfirmBtn] = useState(false);
  const [disableDeclineBtn, setDisableDeclineBtn] = useState(false)
  const [dropoutData, setDropoutData] = useState([])
  const fetchDropData = async () => {
    try {
      setIsLoading(true)
      const res = await getEmployeeDropHistoryAPI();
      console.log(res.data)
      setDropoutData(res.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchDropData();
  }, [])
  function extractDate(dateTimeString) {
    const datePart = dateTimeString.split('T')[0];
    return datePart;
  }

  const handleConfirm = (item) => {
    setDisableConfirmBtn(true)
    confirmEmployeeDropHistoryAPI({ id: item })
      .then((res) => {
        fetchDropData();
        setDisableConfirmBtn(false)
        toast("success", "Successfully Approved!");
      })
      .catch((error) => {
        setDisableConfirmBtn(false)
        console.log(error)
        toast("error", error?.response?.data?.message || "Error while confirming")
      });
  };

  const handleDecline = (item) => {
    setDisableDeclineBtn(true)
    declineEmployeeDropHistoryAPI({ id: item })
      .then((res) => {
        fetchDropData();
        setDisableDeclineBtn(false)
        toast("success", "Declined!");
      })
      .catch((error) => {
        console.log(error)
        setDisableDeclineBtn(false)
        toast("error", error?.response?.data?.message || "Error while declining")
      });
  };

  if (isLoading) {
    return <div className='w-full h-full flex justify-center items-center'>
      <img src='/images/loader2.svg' className='w-20' alt="loader" />
    </div>
  }

  return (
    <div className="flex flex-col gap-6 max-h-[calc(100svh-120px)] overflow-auto">

      {
        !dropoutData.length ? <p className="text-black dark:text-white text-center">{t("No data to display")}</p> : (
          dropoutData.filter(drop => drop.state !== "declined").map(drop => (
            <div
              key={drop?._id} // Assuming each drop has a unique 'id'
              className={`shadow-md flex py-3 rounded-lg justify-between items-center  ${drop?.state === "approved" ? "bg-green-500 text-white" : drop?.state === "declined" ? "bg-red-500 text-white" : "text-black dark:text-white"}`}
            >
              <div className={`text-center px-4 flex-1 lg:w-1/3 ${drop?.state !== "open" ? "" : "border-r"}  border-gray-400`}>
                <h1 className="font-bold text-sm">{extractDate(drop?.shiftPlan?.startTime)}</h1>
                <h1 className="text-sm">{drop?.shiftPlan.startTimeStr} - {drop?.shiftPlan?.endTimeStr}</h1>
                <h1 className="text-sm">{drop?.shiftPlan?.workingArea?.name}</h1>
                <h1 className="font-bold text-sm">{t(drop?.state)}</h1>
              </div>

              {
                drop?.state === "open" && (
                  <div className="flex items-center flex-1 px-2 flex-col lg:w-1 gap-4">
                    <button
                      disabled={disableConfirmBtn}
                      onClick={() => handleConfirm(drop?._id)}
                      className={`px-4 py-1 text-sm font-bold rounded-full shadow-xl text-center ${disableConfirmBtn ? 'bg-green-200 text-green-400 cursor-not-allowed' : 'bg-[#d9f5d5] text-green-600'}`}
                    >
                      {t("Confirm")}
                    </button>
                    <button
                      disabled={disableDeclineBtn}
                      onClick={() => handleDecline(drop?._id)}
                      className={`px-4 py-1 text-sm font-bold rounded-full shadow-xl text-center ${disableDeclineBtn ? 'bg-red-200 text-red-400 cursor-not-allowed' : 'bg-red-100 text-red-600'}`}
                    >
                      {t("Decline")}
                    </button>
                  </div>
                )
              }
            </div>
          ))
        )
      }
    </div>
  );
};

export default Dropout;
