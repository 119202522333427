import axiosClient from "./customApi";
// API routes
export const getEmployeeShiftplansAPI = async (data) => {
  return await axiosClient.post(`/shift-plans/get-employee-shiftplans`, data);
};

export const getEmployeeHolidayIllnessesAPI = async () => {
  return await axiosClient.get(
    `/holiday-illnesses/get-employee-holiday-illnesses`
  );
};

export const getNationalHolidaysEmployeeAPI = async (data) => {
  return await axiosClient.post(
    `/shift-plans/national-employee-holidays`,
    data
  );
};

export const getEmployeeSettings = async () => {
  return await axiosClient.get(`/settings/employee`);
};

export const signInAPI = async (data) => {
  return await axiosClient.put(`/auth/login`, data);
};

export const getEmployeeTimeTracking = async () => {
  return await axiosClient.get("/time-trackings/get-employee-time-trackings");
};

export const confirmByEmployee = async (id) => {
  return await axiosClient.put(`/time-trackings/${id}/confirm-by-employee`);
};

export const getSettings = async () => {
  return await axiosClient.get("/settings");
};

export const updateTimeForEmployee = async (id, dates) => {
  return await axiosClient.put(`/time-trackings/${id}/employee`, dates);
};

export const getEmployeeTimeTrackingsAPI = async () => {
  return await axiosClient.get(
    `/holiday-illnesses/get-employee-holiday-illnesses`
  );
};

export const changePasswordAPI = async (data) => {
  return await axiosClient.put("/auth/change-password", data);
};

export const getEmployeeDetailsAPI = async (id) => {
  return await axiosClient.get(`/employees/${id}`);
};

export const updateHolidayIllnessAPI = async (id, data) => {
  return await axiosClient.put(`/holiday-illnesses/${id}`, data);
};

export const forgotPasswordApi = async (data) => {
  return await axiosClient.put("/settings/forgot-password", {
    email: data,
  });
};

export const confirmResetPasswordAPI = async (data) => {
  return await axiosClient.put("/auth/confirm-reset-password", data);
};

export const getEmployeeDropHistoryAPI = async () => {
  return await axiosClient.get("/dropout-manager/getEmployeeDropHistory");
};

export const createHolidayIllnessAPI = async (data) => {
  return await axiosClient.post(`/holiday-illnesses`, data);
};

export const getSupportEmployeeAPI = async (data) => {
  return await axiosClient.put("/settings/customsupport", data);
};

export const updateTimeTrackingAPI = async (data, id) => {
  return await axiosClient.put(`/holiday-illnesses/${id}`, data);
};

export const deleteHolidayIllnessAPI = async (id) => {
  return await axiosClient.delete(`/holiday-illnesses/${id}`);
};

export const verifyOtpPassword = async (data) => {
  return await axiosClient.put("/settings/verify-otp-change-password", data);
};

export const confirmEmployeeDropHistoryAPI = async (data) => {
  return await axiosClient.post(
    `/dropout-manager/confirmEmployeeDropHistory`,
    data
  );
};
export const declineEmployeeDropHistoryAPI = async (data) => {
  return await axiosClient.post(
    `/dropout-manager/declineEmployeeDropHistory`,
    data
  );
};


