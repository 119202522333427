import React from "react";
import { useTranslation } from "react-i18next";
import { SiGooglemaps } from "react-icons/si";
const DateDetails = ({ dateDetails }) => {
  const { t } = useTranslation();
  console.log(dateDetails, "detate details");
  function formattedDate(date) {
    const todaysDate = new Date(date);
    // Get month name
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = monthNames[todaysDate.getMonth()];

    // Get day and year
    const day = todaysDate.getDate();
    const year = todaysDate.getFullYear();

    // Formatted date string
    return `${month} ${day}, ${year}`;
  }

  function convertToTime(isoDateString) {
    const date = new Date(isoDateString);
    const hours = date.getUTCHours().toString().padStart(2, "0"); // Convert hours to string and ensure two digits
    const minutes = date.getUTCMinutes().toString().padStart(2, "0"); // Convert minutes to string and ensure two digits
    return `${hours}:${minutes}`;
  }

  return (
    <div className="w-full ">
      {dateDetails === null ||
        dateDetails === undefined ||
        !dateDetails.length ? (
        <p className="text-[#000] text-center dark:text-white">
          {t("No data to display")}
        </p>
      ) : (
        <div className="flex flex-col gap-3">
          {dateDetails?.map((details, i) => {
            return ["school", "holiday-payed", "illness", "illness-child", "miscellaneous", "holiday-unpayed", "illness-child"].includes(details?.type) ? (
              <div
                key={i}
                className={`rounded-lg flex bg-[#00831B] flex-col items-center justify-center py-2`}>
                <p className="text-white text-[10px]">
                  {formattedDate(details?.date)} (
                  {`${convertToTime(details?.startTime)} - ${convertToTime(
                    details?.endTime
                  )}`}
                  )
                </p>
                <p className="text-white text-[12px] font-bold">{`${details?.employee?.firstName} ${details?.employee?.lastName}`}</p>
                <p className="text-white text-[12px]">
                  {t("type")}: {details.type}
                </p>
              </div>
            ) : !details?.type ? (
              <div
                key={i}
                className={`rounded-lg flex bg-[#00831B]  flex-col items-center justify-center py-2`}>
                <p className="text-white text-[10px]">
                  {formattedDate(details?.date)} (
                  {`${details?.startTimeStr} - ${details?.endTimeStr}`})
                </p>
                <p className="text-white text-[12px] font-semibold">
                  {details?.workingArea?.name}
                </p>
                {details?.workingArea?.description && (
                  <p className="text-white text-[12px]">
                    {t("Description")}: {details?.workingArea?.description}
                  </p>
                )}
                {details?.workingArea?.address && (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      details?.workingArea?.address
                    )}`}
                    target="_blank"
                    className="text-white text-[12px] flex gap-1 items-center">
                    <span>
                      {t("Address")}: {details?.workingArea?.address}
                    </span>
                    <SiGooglemaps />
                  </a>
                )}
              </div>
            ) : (
              <div
                key={i}
                className={`rounded-lg flex ${details?.backgroundColor} flex-col items-center justify-center py-2`}>
                <p className="text-white text-[10px]">
                  {formattedDate(details?.date)} (
                  18:30 - 18:30
                  )
                </p>
                <p className="text-white text-[12px] font-bold">{details?.name[1]?.text}</p>
                <p className="text-white text-[12px]">
                  {t("type")}: {details.type}
                </p>
              </div>
            )
          })}
        </div>
      )}
    </div>
  );
};

export default DateDetails;
