import React, { useState } from 'react'
import { FaArrowLeft } from "react-icons/fa6";
import { MdOutlineMailOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from '../lib/toastFun';
import { forgotPasswordApi, verifyOtpPassword } from '../lib/apis';
import { Modal } from 'antd';
const ForgotPassword = () => {
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [token, setToken] = useState("")
    const handleForgotPassword = async (e) => {
        e.preventDefault()
        try {
            const res = await forgotPasswordApi(email)
            console.log(res.data)
            toast("success", "Email sent!!");
            // if (res.data.status === false) {
            //     toast("error","Employee not found");

            // }
            if (res.data.status === 200) {
                setShowModal(true)
            }


        } catch (error) {
            toast("error", "error while sending email")
            console.log(error);
        }

    }

    const handleResetPassword = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        if (password !== confirmPassword) {
            toast("error", "password and confirm password should match");
            return;
        }
        try {
            const res = await verifyOtpPassword({
                email: email,
                newPassword: password,
                otp: token
            })
            toast("success", "Password successfully Reset");
            setShowModal(false)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setShowModal(false)
            setIsLoading(false)
        }
    }

    return (
        <div className=''>
            <div className='pt-6 px-4 flex flex-col gap-8'>
                <Link to={"/auth/signIn"}>
                    <FaArrowLeft size={25} />
                </Link>
                <div className='flex flex-col gap-8'>
                    <div className='flex flex-col gap-2'>
                        <h1 className='text-2xl text-[#040404] font-bold'>Forgot Password ?</h1>
                        <p className='text-[#747474] text-[12px]'>Enter your email address and passcode will be sent to you via Email</p>
                    </div>
                    <form onSubmit={handleForgotPassword} className='flex flex-col gap-3'>
                        <div className="p-1 flex gap-2 w-full bg-[#F6F6F6] rounded-lg">
                            <div className=" rounded-lg p-2">
                                <MdOutlineMailOutline color={"#CACACA"} size={30} />
                            </div>
                            <input
                                type="email"
                                name="email"
                                value={email}
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='Enter email address'
                                className="w-full px-1 text-[#000] placeholder:text-[#CCCCCC] dark:text-[#ADBDDB] bg-transparent  outline-none"
                            />
                        </div>
                        <div className={`${email ? "bg-[#540ae6]" : "bg-[#CACACA]"} rounded-lg py-2 flex items-center justify-center`}>
                            <button type="submit" disabled={!email} className='font-bold text-white'>
                                SEND
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Modal className='!w-fit' styles={{ content: { padding: "0" } }}
                style={{ width: "auto" }} footer={false} open={showModal} maskClosable={false} closable={false} >
                <form onSubmit={handleResetPassword} className='flex w-[300px] flex-col py-6 mt-6 pt-7 px-4 gap-4 items-center justify-center'>
                    <h1 className='text-[16px] text-center font-bold'>Reset Password</h1>
                    <div className="p-1 py-3 flex gap-2 w-full bg-[#F6F6F6] rounded-lg">
                        <input
                            type="password"
                            name="password"
                            value={password}
                            required
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='Password'
                            className="w-full px-1 text-[#000] placeholder:text-[#CCCCCC] dark:text-[#ADBDDB] bg-transparent  outline-none"
                        />
                    </div>
                    <div className="p-1 py-3 flex gap-2 w-full bg-[#F6F6F6] rounded-lg">

                        <input
                            type="password"
                            name="confirmPassword"
                            value={confirmPassword}
                            required
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder='Confirm Password'
                            className="w-full px-1 text-[#000] placeholder:text-[#CCCCCC] dark:text-[#ADBDDB] bg-transparent  outline-none"
                        />
                    </div>
                    <div className="p-1 py-3 flex gap-2 w-full bg-[#F6F6F6] rounded-lg">

                        <input
                            type="text"
                            name="token"
                            value={token}
                            required
                            onChange={(e) => setToken(e.target.value)}
                            placeholder='Token'
                            className="w-full px-1 text-[#000] placeholder:text-[#CCCCCC] dark:text-[#ADBDDB] bg-transparent  outline-none"
                        />
                    </div>
                    <p className="text-[12px]">Dear employee. Please take a look in your email account. We send you a new email with OTP code. You need this code for set a new password. Thanks</p>

                    <button type="submit" disabled={setIsLoading} className={`font-bold ${isLoading ? "bg-[#3a0970]" : "bg-[#8757FB]"} text-white w-full rounded-lg py-2 flex items-center justify-center`}>
                        SEND
                    </button>
                </form>
            </Modal>
        </div>
    )
}

export default ForgotPassword