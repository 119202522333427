import React, { useState } from "react";
import { MdOutlineWbSunny } from "react-icons/md";
import { IoMdArrowBack } from "react-icons/io";
import { IoMoonOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import { CiLock } from "react-icons/ci";
import { useAppContext } from "../components/context/Context";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import { message } from "antd";
import { changePasswordAPI } from "../lib/apis";
import { toast } from "../lib/toastFun";
import { useTranslation } from "react-i18next";
const ChangePassword = () => {
  const { t } = useTranslation();
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const navigate = useNavigate()
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [formValues, setFormValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const updatePassword = async (e) => {
    e.preventDefault();
    if (formValues.confirmPassword != formValues.newPassword) {
      toast("error", "New password and confirm password should match");
      return;
    }



    try {
      setIsLoading(true);
      const res = await changePasswordAPI(formValues);
      setIsLoading(false);
      toast("success", "Password successfully changed");
      setFormValues({});
      navigate("/")
    } catch (error) {
      console.log(error, "error while updating the password");
      setIsLoading(false);
      toast("error",
        error?.response?.data?.message ||
        "Error while updating the password please try again!"
      );
      setFormValues({});
    }
  };

  return (
    <>
      <div className="dark:bg-[#15292B] h-[100svh] flex-col flex justify-between">
        <div>
          <Header path={"/profile/accountSettings"} />
          <div className="px-4 py-2 ">
            <form onSubmit={updatePassword} className="flex flex-col gap-4">
              <div className="flex gap-1 flex-col">
                <label
                  className="text-[#ADBDDB] dark:text-[#4C5D6A]"
                  htmlFor="">
                  {t("Enter current password")}
                </label>
                <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] rounded-xl py-2">
                  <div className="bg-[#8757FB] rounded-lg p-2">
                    <CiLock color={"white"} size={25} />
                  </div>
                  <input
                    type={showOldPass ? "text" : "password"}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        oldPassword: e.target.value,
                      })
                    }
                    required
                    value={formValues.oldPassword}
                    placeholder={t("Old Password")}
                    className="w-full px-3 dark:text-[#4C5D6A] bg-transparent text-[#ADBDDB] outline-none"
                  />
                  <button
                    type="button"
                    onClick={() => setShowOldPass(!showOldPass)}>
                    {showOldPass ? (
                      <IoEyeOutline color="#8757FB" size={25} />
                    ) : (
                      <FaRegEyeSlash color="#8757FB" size={25} />
                    )}
                  </button>
                </div>
              </div>
              <div className="flex gap-1 flex-col">
                <label
                  className="text-[#ADBDDB] dark:text-[#4C5D6A]"
                  htmlFor="">
                  {t("Enter new password")}
                </label>
                <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] rounded-xl py-2">
                  <div className="bg-[#8757FB] rounded-lg p-2">
                    <CiLock color={"white"} size={25} />
                  </div>
                  <input
                    type={showNewPass ? "text" : "password"}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        newPassword: e.target.value,
                      })
                    }
                    required
                    value={formValues.newPassword}
                    placeholder={t("New Password")}
                    className="w-full px-3 dark:text-[#4C5D6A] bg-transparent text-[#ADBDDB] outline-none"
                  />
                  <button
                    type="button"
                    onClick={() => setShowNewPass(!showNewPass)}>
                    {showNewPass ? (
                      <IoEyeOutline color="#8757FB" size={25} />
                    ) : (
                      <FaRegEyeSlash color="#8757FB" size={25} />
                    )}
                  </button>
                </div>
              </div>
              <div className="flex gap-1 flex-col">
                <label
                  className="text-[#ADBDDB] dark:text-[#4C5D6A]"
                  htmlFor="">
                  {t("Confirm new password")}
                </label>
                <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] rounded-xl py-2">
                  <div className="bg-[#8757FB] rounded-lg p-2">
                    <CiLock color={"white"} size={25} />
                  </div>
                  <input
                    type={showConfirmPass ? "text" : "password"}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        confirmPassword: e.target.value,
                      })
                    }
                    required
                    value={formValues.confirmPassword}
                    placeholder={t("Confirm Password")}
                    className="w-full px-3 dark:text-[#4C5D6A] bg-transparent text-[#ADBDDB] outline-none"
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPass(!showConfirmPass)}>
                    {showConfirmPass ? (
                      <IoEyeOutline color="#8757FB" size={25} />
                    ) : (
                      <FaRegEyeSlash color="#8757FB" size={25} />
                    )}
                  </button>
                </div>
              </div>
              <div
                className={` ${isLoading ? "bg-[#3a0970]" : "bg-[#8757FB]"
                  }  rounded-lg py-4 px-3 shadow-2xl text-center`}>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="text-[#FCFEF6] text-[16px]">
                  {isLoading ? t("Changing...") : t("Change")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
