import React from "react";
import { useTranslation } from "react-i18next";

const tabNames = [
  {
    name: "All",
    color: "text-[#0000FF]"
  },
  {
    name: "Opened",
    color: "text-[#FA444A]"
  },
  {
    name: "Confirmed",
    color: "text-[#80CA5B]"
  }
]

const ScheduleTabs = ({ tabName, setTabName }) => {
  const { t } = useTranslation()
  return (
    <div className="shadow-md flex py-2 rounded-lg mb-3 justify-around">
      {
        tabNames.map((tab, i) => (

          <button
            key={i}
            onClick={() => setTabName(tab.name)}
            className={`py-2 transition-all w-full text-center duration-500 px-6 ${tabName === tab.name ? `${tab.color}` : "text-black dark:text-white"
              }  rounded-lg`}
          >
            <h4
              className={`${tabName === tab.name ? `${tab.color} bg-white` : "text-black dark:text-white"
                } text-[13px] font-bold py-1   rounded-md transition-all duration-500 `}
            >
              {t(`${tab.name}`)}
            </h4>
          </button>
        ))
      }

    </div>
  );
};

export default ScheduleTabs;
