import React, { useEffect, useState } from "react";
import { ConfigProvider, DatePicker, Select } from "antd";
import { useAppContext } from "../context/Context";
import dayjs from "dayjs";
import "./events.css";
import { useTranslation } from "react-i18next";
import EndTimePicker from "./endTime/EndTimePicker";
import StartTimePicker from "./startTime/StartTimePicker";
import "../../App.css";
import { toast } from "../../lib/toastFun";
import { createHolidayIllnessAPI } from "../../lib/apis";
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(utc);
dayjs.extend(customParseFormat);


const EventDrawer = ({ fetchHolidayIllness, setOpen, startDateAntD, setStartDateAntD, endTimeAntD, setEndDateAntD, endDateAntD, setEndTimeAntD, startTimeAntD, setStartTimeAntD }) => {
  const { theme } = useAppContext();
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation();
  const [selectedState, setSelectedState] = useState("holiday-payed")
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [errorMsg, setErrorMsg] = useState("")
  // const [startTimeAntD, setStartTimeAntD] = useState(null);
  // const [endTimeAntD, setEndTimeAntD] = useState(null);

  const onChangeStartDate = (time) => {
    setStartTimeAntD(time);
  };
  const onChangeEndDate = (time) => {
    setEndTimeAntD(time);
    console.log("endTimeAntD", endTimeAntD)

  };

  useEffect(() => {
    return () => {
      setStartDate();
      setEndDate();
      setEndTimeAntD()
      setStartTimeAntD()
    }
  }, [])

  const handleChange = (value) => {
    setSelectedState(value)
    console.log(`selected ${value}`);
  };
  //   {
  //     "startdate": "2024-05-26",
  //     "enddate": "2024-05-28",
  //     "type": "miscellaneous",
  //     "startTime": "2024-05-26T15:00:00.000Z",
  //     "endTime": "2024-05-28T19:00:00.000Z",
  //     "state": "open",
  //     "mode": "mobile"
  // }

  const handleSave = () => {
    if (!endDate || !endTimeAntD || !startTimeAntD || !startDate) {
      toast("error", "Please fill out all the fields")
      return
    };
    console.log(
      String(startTimeAntD.$d), startDate
    )

    function converTime(time, date) {
      const timeString = time;
      const dateString = date;

      // Extract the time part from the time string
      const timePart = timeString.split(" ")[4];

      // Merge the date string and the time part
      return `${dateString}T${timePart}.000`;
    }


    console.log(startDate)



    let startTime = converTime(String(startTimeAntD.$d), String(startDate))
    let endTime = converTime(String(endTimeAntD.$d), String(endDate))

    // Convert startTime and endTime strings to Date objects
    const start = new Date(startTime);
    const end = new Date(endTime);

    // Compare startTime and endTime
    if (start >= end) {
      console.log("fsdfjsdfkljh")
      toast("error", "Start time cannot be later than or equal to end time.");
      return;
    }



    let result = {
      startTime,
      endTime,
      startdate: startDate,
      enddate: endDate,
      type: selectedState,
      state: "open",
      mode: "mobile",
    }


    //   startdate: startDate, enddate: endDate,
    //   type: selectedState,
    //   startTime,
    //   endTime,
    //   state: "open",
    //   mode: "mobile",
    // }




    createHolidayIllnessAPI(result).then(res => {
      setIsLoading(false)
      toast("success", t("Successfully Created!"))
      fetchHolidayIllness();
      setEndTimeAntD(null)
      setStartTimeAntD(null)
      setStartDateAntD()
      setEndDateAntD()
      setOpen(false)
    }).catch(err => {
      console.log(err)
      toast("error", err?.response?.data?.error || "Error while creating the off time")
    }).finally(() => setIsLoading(false))


    // const getTimeFromObject = (dateObject) => {
    //   const { $d } = dateObject;
    //   const date = new Date($d);
    //   const hours = date.getHours().toString().padStart(2, '0');
    //   const minutes = date.getMinutes().toString().padStart(2, '0');
    //   return `${hours}:${minutes}`;
    // };

    // const startTimeFormate = getTimeFromObject(startTimeAntD);
    // const endTimeFormate = getTimeFromObject(endTimeAntD);

    // const [startHours, startMinutes] = startTimeFormate.split(":").map(Number);
    // const [endHours, endMinutes] = endTimeFormate.split(":").map(Number);

    // const sTime = dayjs(startDate, 'YYYY-MM-DD').set('hour', startHours).set('minute', startMinutes).set('second', 0);
    // const eTime = dayjs(endDate, 'YYYY-MM-DD').set('hour', endHours).set('minute', endMinutes).set('second', 0);

    // const startDateUTC = sTime.utc().toISOString();
    // const endDateUTC = eTime.utc().toISOString();

    // if (sTime.isAfter(eTime)) {
    //   toast("error", t("Start time cannot be later than end time."));
    //   return;
    // }

    // const result = {
    //   startdate: startDate,
    //   enddate: endDate,
    //   type: selectedState,
    //   startTime,
    //   endTime,
    //   state: "open",
    //   mode: "mobile",
    // };

    // console.log("final result send to", result);

    // if (!endDate || !endTimeAntD || !startTimeAntD || !startDate) return

    // const startTimeFormate = getTimeFromObject(startTimeAntD)
    // const endTimeFormate = getTimeFromObject(endTimeAntD)

    // const [startHours, startMinutes] = startTimeFormate
    //   ?.split(":")
    //   .map(Number);
    // const [endHours, endMinutes] = endTimeFormate.split(":").map(Number);


    // const sTime = new Date(`${startDate}`);
    // sTime.setHours(startHours, startMinutes, 0);


    // const eTime = new Date(`${endDate}`);
    // eTime.setHours(endHours, endMinutes, 0);

    // const startDateUTC = new Date(
    //   sTime.getTime() - sTime.getTimezoneOffset() * 60000
    // );
    // const endDateUTC = new Date(
    //   eTime.getTime() - eTime.getTimezoneOffset() * 60000
    // );

    // const startTime = new Date(startDateUTC.toISOString());
    // const endTime = new Date(endDateUTC.toISOString());

    // // console.log( 'new',startTime.getHours(),startTime.getMinutes() , endTime, startTime);

    // if (startTime >= endTime) { // If start time is later than or equal to end time
    //   toast("error", t("Start time cannot be later than or equal to end time."));
    //   return; // Stop the function from proceeding
    // }

    // console.log("final result send to", );
    // setIsLoading(false)



    setIsLoading(false)
  };

  // Call handleSave function with the payload

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf('day');
  };

  return (
    <div className="flex h-fit gap-5 flex-col px-1 py-4">
      <h1 className=" dark:text-white text-black text-center font-bold text-3xl">
        {t("Off Time Creator")}
      </h1>
      <div className="flex gap-3 justify-between">
        <div className="flex flex-col items-center justify-center">
          <p className="dark:text-white text-black whitespace-nowrap">
            {t("Start Date")}
          </p>
          <div className="start-date-picker dark:start-date-picker">
            <DatePicker
              inputReadOnly={true}
              suffixIcon={false}
              dropdownClassName="customDropdown start-dates"
              disabledDate={disabledDate}
              value={startDateAntD}
              onChange={(val) => {
                const date = new Date(val);
                setStartDateAntD(val)
                setStartDate(val?.format('YYYY-MM-DD'))
              }}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="dark:text-white text-black whitespace-nowrap">
            {t("End Date")}
          </p>
          <div className="start-date-picker dark:start-date-picker end-date">
            <DatePicker
              inputReadOnly={true}
              suffixIcon={false}
              popupClassName="smallCalendar end-dates"
              dropdownClassName="customDropdown"
              disabledDate={disabledDate}
              value={endDateAntD}
              onChange={(val) => {
                setEndDateAntD(val)
                const date = new Date(val);
                const formattedDate = date.toISOString().split("T")[0];
                setEndDate(val?.format('YYYY-MM-DD'));
              }}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="dark:text-white text-black whitespace-nowrap">
            {t("Start Time")}
          </p>
          <div className="start-time-picker">
            <StartTimePicker
              value={startTimeAntD}
              dropdownClassName="start-time-picker"
              onChange={onChangeStartDate}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="dark:text-white text-black whitespace-nowrap">
            {t("End Time")}
          </p>
          <div className="end-time-picker ">
            <EndTimePicker value={endTimeAntD} onChange={onChangeEndDate} />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <h1 className="dark:text-white text-black">{t("State")}</h1>
        <ConfigProvider
          theme={{
            token: {
              selectorBg: theme === "light" ? "" : "#15292B",
              colorText: theme === "light" ? "#000" : "#fff",
              selectorBg: theme === "light" ? "#fff" : "#000",
              colorBgContainer: theme === "light" ? "#fff" : "#15292B",
            },
          }}>
          <Select
            defaultValue={t("Holiday Payed")}
            className="off-time-option"
            style={{ width: "100%" }}
            onChange={handleChange}
            size="large"
            options={[
              { value: "holiday-payed", label: t("Holiday Payed") },
              { value: "holiday-unpayed", label: t("Holiday Unpayed") },
              { value: "illness", label: t("Illness") },
              { value: "school", label: t("School") },
              { value: "miscellaneous", label: t("Miscellaneous") },
              { value: "illness-child", label: t("Illness child") },
            ]}
          />
        </ConfigProvider>
      </div>
      <button disabled={isLoading} onClick={handleSave} className={`bg-[#540ae6] ${isLoading ? "bg-[#540ae6]/80" : "bg-[#540ae6]"} text-white text-[16px] w-full rounded-lg py-2 px-3 shadow-2xl text-center`}>
        {t("Save")}
      </button>
    </div>
  );
};

export default EventDrawer;
