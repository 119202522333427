import React from "react";
import { useTranslation } from "react-i18next";

const ViewTabs = ({ tabName, setTabName }) => {
  const { t } = useTranslation()
  return (
    <div className="shadow-sm flex py-2 rounded-lg justify-around">
      <div
        onClick={() => setTabName("Calender View")}
        className={`py-1 transition-all duration-500 px-4 ${tabName === "Calender View" ? "bg-[#E2EAFF] dark:bg-[#2E4682]" : "bg-white dark:bg-transparent"
          }  rounded-lg`}
      >
        <h4
          className={`${tabName === "Calender View" ? " text-[#5A78F0]" : "text-black dark:text-[#ADBDDB]"
            } text-[12px]  font-bold transition-all duration-500 `}
        >
          {t("Calender View")}
        </h4>
      </div>
      <div
        onClick={() => setTabName("List View")}
        className={`py-1 px-4 ${tabName === "List View" ? "bg-[#E2EAFF] dark:bg-[#2E4682]" : "bg-white dark:bg-transparent"
          } rounded-lg transition-all duration-500 `}
      >
        <h4
          className={` ${tabName === "List View" ? "text-[#FF0000] " : "text-black dark:text-[#ADBDDB]"
            } font-bold text-[12px] transition-all duration-500`}
        >
          {t("List View")}
        </h4>
      </div>
    </div>
  );
};

export default ViewTabs;
