import axios from "axios";
import { getToken, setToken } from "./localstorage";
// import { ACCESS_TOKEN, REFRESH_TOKEN, USER_DATA, getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "./localStroageManager";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

// Add a request interceptor
axiosClient.interceptors.request.use((request) => {
  const accessToken = getToken("access_token");
  if (accessToken) {
    request.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return request;
});

// Add a response interceptor
axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // // If the error status is 401 and there is no originalRequest._retry flag,
    // // it means the token has expired and we need to refresh it
    if (
      error.response?.data?.status === "fail" &&
      error?.response?.data?.message === "Not authenticated"
    ) {
      try {
        localStorage.removeItem("access_token");
        window.location.replace("/login", "_self");
        return axios(originalRequest);
      } catch (error) {
        window.location.replace("/login", "_self");
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
