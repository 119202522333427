import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import SignIn from "./pages/SignIn";
import DatePage from "./pages/DatePage";
import SharedRoute from "./pages/SharedRoute";
import { BrowserRouter } from "react-router-dom";
import Schedule from "./pages/Schedule";
import Profile from "./pages/Profile";
import Events from "./pages/Events";
import ProfileSettings from "./pages/ProfileSettings";
import ForgetPassword from "./pages/ChangePassword";
import AccountSettings from "./pages/AccountSettings";
import ProfileEdit from "./pages/ProfileEdit";
import Language from "./pages/Language";
import Dropout from "./pages/Dropout";
import { setToast } from "./lib/toastFun";
import SupportForm from "./pages/SupportForm";
import ForgotPassword from "./pages/ForgotPassword";

const PrivateRoutes = () => {
  const isAuthenticatedString = localStorage.getItem("access_token");
  return isAuthenticatedString ? <MainRoute /> : <Navigate to="/auth/signIn" />;
};
const MainRoute = () => {
  const { contextHolder } = setToast();
  return (
    <>
      {contextHolder}
      <Routes>
        <Route path="/" element={<SharedRoute />}>
          <Route index element={<DatePage />} />
          <Route path="Schedule" element={<Schedule />} />
          <Route path="profile" element={<Profile />} />
          <Route path="events" element={<Events />} />
          <Route path="dropout" element={<Dropout />} />
        </Route>
        <Route path="profile/profileEdit" element={<ProfileEdit />} />
        <Route path="account/settings" element={<ProfileSettings />} />
        <Route path="profile/accountSettings" element={<AccountSettings />} />
        <Route path="/changePassword" element={<ForgetPassword />} />
        <Route path="account/language" element={<Language />} />
        <Route path="/support" element={<SupportForm />} />
      </Routes>
    </>
  );
};
function App() {
  const { contextHolder } = setToast();

  return (
    <>
      <BrowserRouter>
        {contextHolder}
        <Routes>
          <Route path="/auth/signIn" element={<SignIn />} />
          <Route path="*" element={<PrivateRoutes />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
