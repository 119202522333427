import { TimePicker } from "antd";
import React from "react";

const StartTimePicker = ({ value, onChange }) => {
  return (
    <TimePicker
      suffixIcon={false}
      inputReadOnly={true}
      placement="topLeft"
      value={value}
      format={"HH:mm"}
      onChange={onChange}
    />
  );
};

export default StartTimePicker;
