import React, { useEffect, useState } from "react";
import { HiMenuAlt2 } from "react-icons/hi";
import { MdOutlineWbSunny } from "react-icons/md";
import { useAppContext } from "../context/Context";
import { IoMoonOutline } from "react-icons/io5";
import { Button, ConfigProvider, Drawer } from "antd";
import Sidebar from "./Sidebar";
const Navbar = () => {
  const { theme, setTheme } = useAppContext();
  const toggleDarkMode = () => {
    const htmlTag = document.querySelector("html");
    htmlTag.classList.toggle("dark");
    setTheme(theme === "light" ? "dark" : "light");
  };

  const [showSidebar, setShowSidebar] = useState(false);
  // const onClose = () => {
  //   setShowSidebar(false)
  // }
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Disable scrolling when modal is open
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    // Cleanup function to remove the added style
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <>
      <div className="flex justify-between px-3 h-20">
        <button onClick={() => showDrawer()}>
          <HiMenuAlt2 size={20} color="#540ae6" className="dark:hidden block" />
          <HiMenuAlt2 size={20} color="#fff" className="dark:block hidden" />
        </button>
        <div className="flex items-center">
          <img className="w-[70px] dark:invert" src="/images/roster_logo.png" alt="" />
        </div>
        <button onClick={toggleDarkMode} className="">
          {theme === "light" ? (
            <IoMoonOutline size={20} />
          ) : (
            <MdOutlineWbSunny size={20} color="#fff" />
          )}
        </button>
        {/* <Sidebar open={showSidebar} setOpen={setShowSidebar} /> */}
      </div>

      <>
        <ConfigProvider
          theme={{
            token: {
              colorBgElevated: theme === "light" ? "#fff" : "#15292B",
            },
          }}>
          <Drawer
            closable={false}
            width={"auto"}
            onClose={onClose}
            placement="left"
            open={open}>
            <Sidebar open={open} setOpen={setOpen} />
          </Drawer>
        </ConfigProvider>
      </>
    </>
  );
};

export default Navbar;
