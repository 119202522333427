import React, { useEffect, useState } from "react";
import Header from "../components/shared/Header";
import { Switch } from "antd";
import { useTranslation } from "react-i18next";

const Language = () => {
  const { t, i18n } = useTranslation();
  const [defaultChecked, setDefaultChecked] = useState(true);
  const switchStyle = defaultChecked ? "switch-enabled" : "switch-disabled";
  // Assuming lang is a parameter in the URL
  // Example function to change language

  useEffect(() => {
    let selectedLang = localStorage.getItem("lang");

    setDefaultChecked(selectedLang === "en");
  }, []);

  const changeLanguage = (language) => {
    setDefaultChecked(language);
    if (language) {
      localStorage.setItem("lang", "en");
      i18n.changeLanguage(localStorage.getItem("lang"));
    } else {
      localStorage.setItem("lang", "de");
      i18n.changeLanguage(localStorage.getItem("lang"));
    }
  };

  return (
    <div className="dark:bg-[#15292B] h-[100svh]">
      <Header path={`/profile/accountSettings`} />
      <div className="px-10 py-6">
        <div className="flex gap-5 items-center">
          <div className="flex gap-3 items-center">
            <img
              className="w-8 h-8 object-contain"
              src="/images/flag.jpg"
              alt=""
            />
            <h5 className="dark:text-[#ADBDDB]">English</h5>
          </div>
          <div>
            <Switch
              checkedChildren="EN"
              unCheckedChildren="DE"
              className={switchStyle}
              onChange={(value) => changeLanguage(value)}
              value={defaultChecked}
            />
          </div>
          <div className="flex gap-3 items-center">
            <img
              className="w-8 h-12 object-contain"
              src="/images/germany.png"
              alt=""
            />
            <h5 className="dark:text-[#ADBDDB]">{t("Germany")}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Language;
