import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './lang/en.json'
import de from './lang/de.json'
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: en },
      de: { translation: de },
    },
    lng: "en", // Initial language
    fallbackLng: "en", // Use this if the selected language translations are missing
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;