import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { Calendar, Col, Row } from "antd";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import dayLocaleData from "dayjs/plugin/localeData";
import { useAppContext } from "../context/Context";
import "../../App.css";
dayjs.extend(dayLocaleData);

const CalenderComp = ({ nationalHolidays, month, setMonth, tabName, holidayIllness, employeeShifts, setDateDetails, setDate, date }) => {
  const { theme: mode } = useAppContext();
  const [activeDate, setActiveDate] = useState(`${dayjs().date()} : ${dayjs().month()}`);
  const [allDates, setAllDates] = useState([])
  // useEffect(() => {

  // }, [date, nationalHolidays, holidayIllness, employeeShifts, tabName]);

  const wrapperStyle = {
    width: "100%",
    // border: `1px solid ${token.colorBorderSecondary}`,
    // borderRadius: token.borderRadiusLG,
  };

  const handlePanelChange = (value, mode) => {
    if (mode === "month") {
      const firstDay = value.startOf("month").startOf("day");
      const lastDay = value.endOf("month").endOf("day");
      const formattedStartDate = firstDay.format("YYYY-MM-DD");
      const formattedEndDate = lastDay.format("YYYY-MM-DD");
      setDate({
        start: firstDay.toISOString(),
        end: lastDay.toISOString(),
      });
      setMonth({
        start: formattedStartDate,
        end: formattedEndDate,
      });
    }
  };

  function addDateAndColor(array, bgColor) {
    if (!array) return [];
    return array?.map(obj => {
      const startTime = new Date(obj.startTime)

      return {
        ...obj,
        date: startTime,
        backgroundColor: bgColor,
      };
    });
  }

  function addBackgroundColorToDate(array, bgColor) {
    if (!array) return [];
    return array.map(obj => {
      const dateObj = new Date(obj.startDate);
      return {
        ...obj,
        date: dateObj, // Store the converted date as 'date' attribute
        backgroundColor: bgColor,
      };
    });
  }

  useEffect(() => {
    const transformedHolidayIllness = addDateAndColor(holidayIllness, "bg-[#FFCCCB]"); // Light pink
    const transformedNationalHolidays = addBackgroundColorToDate(nationalHolidays, "bg-[#FF5F11]"); // Gold
    const transformedEmployeeShifts = addDateAndColor(employeeShifts, "bg-[#4FCEBD]"); // Light green


    setAllDates([
      ...transformedHolidayIllness,
      ...transformedNationalHolidays,
      ...transformedEmployeeShifts,
    ])


    const today = new Date();
    const todayData = allDates.filter((dateObj) => {
      const dateFromAllDates = dateObj.date;
      return dateFromAllDates.getDate() === today.getDate() &&
        dateFromAllDates.getMonth() === today.getMonth() &&
        dateFromAllDates.getFullYear() === today.getFullYear();
    });
    setDateDetails(todayData);
  }, [date, nationalHolidays, holidayIllness, employeeShifts, tabName])


  useEffect(() => {
    const today = new Date();
    const todayData = allDates.filter((dateObj) => {
      const dateFromAllDates = dateObj.date;
      return (
        dateFromAllDates.getDate() === today.getDate() &&
        dateFromAllDates.getMonth() === today.getMonth() &&
        dateFromAllDates.getFullYear() === today.getFullYear()
      );
    });
    setDateDetails(todayData);
  }, [allDates]);


  const cellRender = (current) => {
    const currentDate = new Date(current.$d);
    const today = new Date(); // Get today's date

    // Check if the current date is today
    const isToday = currentDate.getDate() === today.getDate() &&
      currentDate.getMonth() === today.getMonth() &&
      currentDate.getFullYear() === today.getFullYear();

    // Find all matched date objects based on the current date
    const matchedDates = allDates.filter((dateObj) => {
      const dateFromAllDates = dateObj.date;
      return (
        dateFromAllDates.getFullYear() === currentDate.getFullYear() &&
        dateFromAllDates.getMonth() === currentDate.getMonth() &&
        dateFromAllDates.getDate() === currentDate.getDate()
      );
    });

    // Get the background color of the first matched date object
    const backgroundColor = matchedDates.length > 0 ? matchedDates[0].backgroundColor : '';

    if (matchedDates.length > 0) {
      return (
        <div onClick={() => {
          setDateDetails(matchedDates);
          setActiveDate(`${currentDate.getDate()} : ${currentDate.getMonth()}`);
        }}
          className={`${activeDate == `${currentDate.getDate()} : ${currentDate.getMonth()}` ? "bg-sky-500" : backgroundColor} text-black ml-3 w-7 h-7 text-[11px] flex items-center justify-center rounded-full `}>
          {currentDate.getDate()}
        </div>
      );
    } else {
      if (isToday) {
        // If it's today's date, check if there's data for today in allDates
        const dataForToday = allDates?.find((dateObj) => {
          const dateFromAllDates = dateObj.date;
          return dateFromAllDates.getDate() === today.getDate() &&
            dateFromAllDates.getMonth() === today.getMonth() &&
            dateFromAllDates.getFullYear() === today.getFullYear();
        });

        if (dataForToday) {
          // If there's data for today, set the dateDetails
          setDateDetails(dataForToday);
        }
      }
      return (
        <div onClick={() => {
          setActiveDate(`${currentDate.getDate()} : ${currentDate.getMonth()}`);
          setDateDetails(null)
        }} className={`${activeDate === `${currentDate.getDate()} : ${currentDate.getMonth()}` ? "bg-sky-500" : ""} text-black ml-3 w-7 h-7 text-[11px] flex items-center justify-center rounded-full `}>
          {currentDate.getDate()}
        </div>
      );
    }
  };




  return (
    <div style={wrapperStyle}>
      {/* <ConfigProvider
        theme={{
          components: {
            Calendar: {
              fullBg: mode === "light" ? "#fff" : "#007ACC",
              fullPanelBg: mode === "light" ? "#fff" : "#007ACC",
              colorText: mode === "light" ? "#000" : "#fff",
            },
          },
        }}
      > */}
      <Calendar
        fullscreen={false}
        dateFullCellRender={cellRender}
        defaultValue={dayjs()}
        className={mode === "light" ? "" : "dashboard-calender"}
        headerRender={({ value, onChange }) => {
          return (
            <div style={{ padding: "14px 0" }}>
              <Row
                gutter={8}
                justify="between"
                className="flex items-center justify-between">
                <Col>
                  <button
                    className="ml-6"
                    onClick={() => onChange(value.subtract(1, "month"))}>
                    <IoMdArrowDropleft color="#16AEE2" />
                  </button>
                </Col>
                <Col>
                  <span>{value.format("MMMM YYYY")}</span>
                </Col>
                <Col>
                  <button
                    className="mr-6"
                    onClick={() => onChange(value.add(1, "month"))}>
                    <IoMdArrowDropright color="#16AEE2" />
                  </button>
                </Col>
              </Row>
            </div>
          );
        }}
        onPanelChange={handlePanelChange}
        mode="month"
      />
      {/* </ConfigProvider> */}
    </div>
  );
};

export default CalenderComp;
