import React, { useEffect, useState } from 'react'
import CustomDrawer from '../CustomDrawer'
import EditShiftDrawer from '../drawer/EditShiftDrawer'
import { toast } from '../../lib/toastFun';
import { useTranslation } from 'react-i18next';

const ScheduleCard = ({ employee, confirmEmployee, confirmBtnDisable, setEditId, editShifts, editId, fetchEmployeeTimeTracking }) => {
    const [dummyOpen, setDummyOpen] = useState(false);
    const { t } = useTranslation()


    const handleEdit = (id) => {
        if (editShifts) {
            setDummyOpen(true);
            setEditId(id)
        } else {
            toast('error', 'Feature is not activated at the moment. Please ask your Workspace Admin for further questions. Thanks.')
        }
    }

    return (
        <>
            <div
                className={`shadow-md flex py-3  rounded-lg justify-around items-center ${employee?.confirmedByEmployee && "bg-green-500"}  `}
            >
                <div className={`text-center px-4 lg:w-1/3 ${!employee?.confirmedByEmployee && "border-r"}  border-gray-400 `}>
                    <h1 className="font-bold text-sm">{employee.date}</h1>
                    <h1 className="text-sm">{`${employee.workingArea.name}: ${employee.startTime} - ${employee.endTime}`}</h1>
                    <h1 className="font-bold text-sm ">{employee?.confirmedByEmployee ? t("Confirmed") : t("Open")} </h1>
                </div>
                {
                    !employee?.confirmedByEmployee && (

                        <div className="flex w-[100px] px-2 flex-col lg:w-1/ gap-4">
                            <button onClick={() => confirmEmployee(employee._id)} disabled={confirmBtnDisable} className="bg-[#d9f5d5] text-green-600 p-1 text-sm font-bold rounded-full shadow-xl text-center disabled:bg-[#d9f5d5]/60">
                                Confirm
                            </button>
                            <button onClick={() => handleEdit(employee._id)} className="bg-[#ebcacf] text-green-600 p-1 text-sm font-bold rounded-full shadow-xl text-center">
                                Edit
                            </button>
                        </div>
                    )
                }
            </div>
            {
                editShifts && (

                    <CustomDrawer onClose={() => setDummyOpen(false)} open={dummyOpen}>
                        <EditShiftDrawer id={editId} fetchEmployeeTimeTracking={fetchEmployeeTimeTracking} setDummyOpen={setDummyOpen} date={employee.date} startTime={employee.startTime} endTime={employee.endTime} />
                    </CustomDrawer>
                )
            }


        </>
    )
}

export default ScheduleCard