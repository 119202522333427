export const setToken = (token) => {
    localStorage.setItem("access_token", JSON.stringify(token))
}

export const getToken = (tokenName) => {
    const result =  localStorage.getItem(tokenName);
    const token = result ? JSON.parse(result) : null
    return token
}

