import React, { useEffect, useState } from "react";
import { FiUser } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { getEmployeeSettings } from "../lib/apis";
import Header from "../components/shared/Header";
import { useTranslation } from "react-i18next";
const ProfileEdit = () => {
  const { t } = useTranslation();
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [editableFields, setEditableFields] = useState({
    firstName: "",
    email: "",
    lastName: "",
  });
  const fetchEmployeerSettings = async () => {
    try {
      const res = await getEmployeeSettings();
      setEmployeeDetails(res.data);
      setEditableFields({
        firstName: res.data.firstName || "",
        email: res.data.email || "",
        lastName: res.data.lastName || "",
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchEmployeerSettings();
  }, []);

  // Update editable field state when input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  return (
    <div className="h-[100svh] dark:bg-[#15292B]">
      <Header path={"/profile"} />
      <div className="flex p-4 dark:bg-[#15292B] flex-col gap-3 max-h-[calc(100svh-70px)] overflow-auto">
        <div className="flex gap-1 flex-col">
          <label className="text-black dark:text-[#4C5D6A]" htmlFor="firstName">
            {t("First Name")}
          </label>
          <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2">
            <div className="bg-[#8757FB] rounded-lg p-2">
              <FiUser color={"white"} size={30} />
            </div>
            <input
              type="text"
              name="firstName"
              onChange={handleInputChange}
              className="w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none"
              value={editableFields.firstName}
            />
          </div>
        </div>
        <div className="flex gap-1 flex-col">
          <label className="text-black  dark:text-[#4C5D6A]" htmlFor="lastName">
            {t("Last Name")}
          </label>
          <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2">
            <div className="bg-[#8757FB] rounded-lg p-2">
              <FiUser color={"white"} size={30} />
            </div>
            <input
              type="text"
              name="lastName"
              onChange={handleInputChange}
              className="w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none"
              value={editableFields.lastName}
            />
          </div>
        </div>
        <div className="flex gap-1 flex-col">
          <label className="text-black dark:text-[#4C5D6A]" htmlFor="email">
            {t("Email Address")}
          </label>
          <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2">
            <div className="bg-[#8757FB] rounded-lg p-2">
              <MdOutlineMailOutline color={"white"} size={30} />
            </div>
            <input
              type="email"
              name="email"
              onChange={handleInputChange}
              className="w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none"
              value={editableFields.email}
            />
          </div>
        </div>
        <div className="flex gap-1 flex-col">
          <label className="text-black dark:text-[#4C5D6A]" htmlFor="">
            {t("Role")}
          </label>
          <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2">
            <div className="bg-[#8757FB] rounded-lg p-2">
              <FiUser color={"white"} size={30} />
            </div>
            <input
              disabled
              type="text"
              className="w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none"
              value={employeeDetails?.role}
            />
          </div>
        </div>
        <div className="flex gap-1 flex-col">
          <label className="text-black dark:text-[#4C5D6A]" htmlFor="">
            {t("Work Time week")}
          </label>
          <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2">
            <div className="bg-[#8757FB] rounded-lg p-2">
              <FiUser color={"white"} size={30} />
            </div>
            <input
              disabled
              type="text"
              className="w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none"
              value={`${employeeDetails?.workingTimeWeek} h `}
            />
          </div>
        </div>
        <div className="flex gap-1 flex-col">
          <label className="text-black dark:text-[#4C5D6A]" htmlFor="">
            {t("Work Time Month")}
          </label>
          <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2">
            <div className="bg-[#8757FB] rounded-lg p-2">
              <FiUser color={"white"} size={30} />
            </div>
            <input
              disabled
              type="text"
              className="w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none"
              value={`${employeeDetails?.workingTimeMonth} h `}
            />
          </div>
        </div>
        <div className="flex gap-1 flex-col">
          <label className="text-black dark:text-[#4C5D6A]" htmlFor="">
            {t("Holiday This Year")}
          </label>
          <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2">
            <div className="bg-[#8757FB] rounded-lg p-2">
              <FiUser color={"white"} size={30} />
            </div>
            <input
              disabled
              type="text"
              className="w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none"
              value={`${employeeDetails?.holidayThisYear} hours `}
            />
          </div>
        </div>
        <div className="flex gap-1 flex-col">
          <label className="text-black dark:text-[#4C5D6A]" htmlFor="">
            {t("Holiday Contact")}
          </label>
          <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2">
            <div className="bg-[#8757FB] rounded-lg p-2">
              <FiUser color={"white"} size={30} />
            </div>
            <input
              disabled
              type="text"
              className="w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none"
              value={`${employeeDetails?.holidayContacts} days per Year`}
            />
          </div>
        </div>
        <div className="flex gap-1 flex-col">
          <label className="text-black dark:text-[#4C5D6A]" htmlFor="">
            {t("Amount Of Confirmed Overtaking Requests")}
          </label>
          <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2">
            <div className="bg-[#8757FB] rounded-lg p-2">
              <FiUser color={"white"} size={30} />
            </div>
            <input
              disabled
              type="text"
              className="w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none"
              value={`${employeeDetails?.overTackings} of this year `}
            />
          </div>
        </div>
        <div className="flex gap-1 flex-col">
          <label className="text-black dark:text-[#4C5D6A]" htmlFor="">
            {t("Amount Of Declined Overtaking Requests")}
          </label>
          <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2">
            <div className="bg-[#8757FB] rounded-lg p-2">
              <FiUser color={"white"} size={30} />
            </div>
            <input
              disabled
              type="text"
              className="w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none"
              value={`${employeeDetails?.DeclinedoverTackings} of this year `}
            />
          </div>
        </div>
        <div className="flex gap-1 flex-col">
          <label className="text-black dark:text-[#4C5D6A]" htmlFor="">
            {t("Sickness This Year Employee")}
          </label>
          <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2">
            <div className="bg-[#8757FB] rounded-lg p-2">
              <FiUser color={"white"} size={30} />
            </div>
            <input
              disabled
              type="text"
              className="w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none"
              value={`${employeeDetails?.sicknessEmployee} hours `}
            />
          </div>
        </div>
        <div className="flex gap-1 flex-col">
          <label className="text-black dark:text-[#4C5D6A]" htmlFor="">
            {t("Sickness This Year (Child & Employee)")}
          </label>
          <div className="p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2">
            <div className="bg-[#8757FB] rounded-lg p-2">
              <FiUser color={"white"} size={30} />
            </div>
            <input
              disabled
              type="text"
              className="w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none"
              value={`${employeeDetails?.sicknessHours} hours this year(illness & illness child) `}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;
