import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ListView = ({ nationalHolidays, holidayIllness, setDateDetails, employeeShifts }) => {
    const { t } = useTranslation()
    const [currentDate, setCurrentDate] = useState(new Date()); // State to hold the current date

    useEffect(() => {
        // Find the object from allDates whose startTime matches with currentDate
        const matchingDates = allDates.filter(date => {
            const dateStartTime = new Date(date.startTime);
            return (
                dateStartTime.getDate() === currentDate.getDate() &&
                dateStartTime.getMonth() === currentDate.getMonth() &&
                dateStartTime.getFullYear() === currentDate.getFullYear()
            );
        });

        // Set the found objects in setDateDetails
        setDateDetails(matchingDates);
    }, [currentDate]); // Update whenever currentDate changes

    function addDateAndColor(array) {
        if (!array) return [];
        return array?.map(obj => {
            const startTime = new Date(obj.startTime);
            return {
                ...obj,
                date: startTime,
            };
        });
    }

    const transformedHolidayIllness = addDateAndColor(holidayIllness); // Light pink
    const transformedNationalHolidays = addDateAndColor(nationalHolidays); // Gold
    const transformedEmployeeShifts = addDateAndColor(employeeShifts); // Light green

    const allDates = [
        ...transformedHolidayIllness,
        ...transformedNationalHolidays,
        ...transformedEmployeeShifts,
    ];

    const handlePrevious = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() - 1); // Decrement current date by 1 day
        setCurrentDate(newDate);
    };

    const handleToday = () => {
        setCurrentDate(new Date()); // Set current date to today
    };

    const handleNext = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() + 1); // Increment current date by 1 day
        setCurrentDate(newDate);
    };

    return (
        <div>
            <div className='bg-[#F0F0F0] p-2'>
                <div className='flex justify-between'>
                    <button className='bg-blue-500 text-[12px] text-white px-2 py-1' onClick={handlePrevious}>
                        {t("Prev")}
                    </button>
                    <button className='bg-blue-500 text-[12px] text-white px-2 py-1' onClick={handleToday}>
                        {t("Today")}
                    </button>
                    <button className='bg-blue-500 text-[12px] text-white px-2 py-1' onClick={handleNext}>
                        {t("Next")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ListView;
