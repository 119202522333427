import React, { useState, useRef } from "react";
import Header from "../components/shared/Header";
import { getSupportEmployeeAPI } from "../lib/apis";

const SupportForm = () => {
  const [reference, setReference] = useState("");
  const [message, setMessage] = useState("");
  const [referenceError, setReferenceError] = useState("");
  const [messageError, setMessageError] = useState("");
  const fileInputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset errors
    setReferenceError("");
    setMessageError("");

    // Validation
    if (!reference.trim()) {
      setReferenceError("Reference is required");
    }

    if (!message.trim()) {
      setMessageError("Message is required");
    }

    // If any field is empty, return
    if (!reference.trim() || !message.trim()) {
      return;
    }

    getSupportEmployeeAPI({ reference: reference, content: message })
      .then((res) => {
        console.log(res.data, "custom support");
      })
      .catch((err) => console.log(err));

    // If validation passes, continue with sending the form
    // Your logic here for sending the form data
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="dark:bg-[#15292B] h-screen">
      <Header path={"/"} />
      <div className="px-3 mt-4 flex flex-col gap-5">
        <h1 className="text-[#343434] font-bold dark:text-white">
          Please write in details what is the problem ? Our support will answer
          you within 48 hours.
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="w-full flex flex-col gap-4">
            <input
              type="text"
              placeholder="Reference"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
              className="py-3 w-full outline-none  px-2 rounded-lg border border-[#A1A1A1] text-black dark:text-white dark:placeholder:text-white dark:bg-[#4f7a7f]  dark:border-[#232F43]"
            />
            {referenceError && <p className="text-red-500">{referenceError}</p>}
            <textarea
              name="message"
              placeholder="Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="py-10 pb-4 w-full outline-none  px-2 border-[#A1A1A1] text-black dark:placeholder:text-white border dark:bg-[#4f7a7f]  rounded-lg dark:text-white dark:border-[#232F43] "
            />
            {messageError && <p className="text-red-500">{messageError}</p>}
          </div>
          <div className="flex gap-4 mt-4 justify-start">
            <div
              className="bg-[#8757FB]  rounded-lg py-2 px-3 shadow-2xl text-center cursor-pointer"
              onClick={handleUploadClick}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <span className="text-white text-[16px]">Upload File</span>
            </div>
            <div className="bg-[#8757FB] rounded-lg py-2 px-3 shadow-2xl text-center">
              <button type="submit" className="text-white text-[16px]">
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SupportForm;
