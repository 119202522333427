import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { jwtDecode } from "jwt-decode";
// import { useNavigate } from "react-router-dom";
const appContext = createContext();
export const ContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const userData = JSON.parse(localStorage.getItem("user"));
  // const router = useNavigate();
  const [userInfo, setUserInfo] = useState({})
  const [theme, setTheme] = useState("light")
  const { i18n } = useTranslation();
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("user"))
    setUserInfo(userData)
    let selectedLang = localStorage.getItem("lang")
    if (!selectedLang) {
      localStorage.setItem('lang', 'en')
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage(localStorage.getItem('lang'));
    }

    // if (localStorage.getItem("access_token")) {

    //   const token = localStorage.getItem("access_token");
    //   const decoded = jwtDecode(token)
    //   if (Date.now() > decoded.exp) {
    //     localStorage.removeItem("access_token")
    //     // router("/login")
    //   }
    //   console.log("decoded", decoded)
    // }


  }, []);



  return (
    <appContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        theme,
        setTheme,
        userInfo,
        setUserInfo
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(appContext);
};
