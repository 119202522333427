import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/shared/Navbar";
import Footer from "../components/shared/Footer";

const SharedRoute = () => {
  return (
    <div className="flex dark:bg-[#15292B] flex-col w-full h-[100svh]">
      <Navbar />
      <div className="p-3">
        <Outlet />
      </div>
      <div className="mt-auto">
        <Footer />
        {/* <h1 className="text-5xl">fsdfjdskfjdk</h1> */}
      </div>
    </div>
  );
};

export default SharedRoute;
