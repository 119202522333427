import React, { useState } from "react";

const Calendar = ({ setSelectedYear, setSelectedMonth }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());


  const handleNextMonth = () => {
    const nextMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
    setCurrentMonth(nextMonth);
    const nextMonthYear = nextMonth.getFullYear();
    const nextMonthIndex = nextMonth.getMonth();
    setSelectedMonth(nextMonthIndex);
    setSelectedYear(nextMonthYear);
  };

  const handlePrevMonth = () => {
    const nextMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1);
    setCurrentMonth(nextMonth);
    const nextMonthYear = nextMonth.getFullYear();
    const nextMonthIndex = nextMonth.getMonth();
    setSelectedMonth(nextMonthIndex);
    setSelectedYear(nextMonthYear);
  };

  const formattedMonth = `${currentMonth.toLocaleString("default", {
    month: "long",
  })} ${currentMonth.getFullYear()}`;

  return (
    <div className="flex justify-center">
      <div
        className="flex items-center gap-1
      "
      >
        <button
          dir="ltr"
          className="px-4 py-1 rounded-s-lg bg-[#E5DBFF] dark:bg-[#3F438A] text-[10px] font-bold text-[#8757FB] "
          onClick={handlePrevMonth}
        >
          {"<"}
        </button>
        <div className="px-3 sm:px-4 py-1 bg-[#E5DBFF] dark:bg-[#3F438A] rounded font-bold text-[10px] text-[#8757FB]">
          {formattedMonth}
        </div>
        <button
          dir="rtl"
          className="px-4 py-1 rounded-s-lg bg-[#E5DBFF] dark:bg-[#3F438A] text-[10px] font-bold text-[#8757FB]  "
          onClick={handleNextMonth}
        >
          {"<"}
        </button>
      </div>
    </div>
  );
};

export default Calendar;
