import React from 'react'
import { RiAccountCircleFill } from "react-icons/ri";
import { FaChevronRight } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Profile = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const handleLogOut = () => {
        localStorage.removeItem("access_token");
        navigate("/auth/signIn");
    };
    return (
        <div>
            <div className='flex flex-col gap-5 px-4'>
                <Link to={"/profile/profileEdit"} className='flex justify-between items-center'>
                    <button className='flex gap-4 items-center'>
                        <RiAccountCircleFill size={25} className={"text-black dark:text-[#ADBDDB]"} />
                        <h5 className='text-black font-bold dark:text-[#ADBDDB]'>{t("Profile")}</h5>
                    </button>
                    <div>
                        <FaChevronRight className={"text-black dark:text-[#ADBDDB]"} />
                    </div>
                </Link>
                <Link to={"/profile/accountSettings"} className='flex justify-between items-center'>
                    <div className='flex gap-4 items-center'>
                        <IoMdSettings size={25} className={"text-black dark:text-[#ADBDDB]"} />
                        <h5 className='text-black font-bold dark:text-[#ADBDDB]'>{t("Account Settings")}</h5>
                    </div>
                    <div>
                        <FaChevronRight className={"text-black dark:text-[#ADBDDB]"} />
                    </div>
                </Link>
                <div className='flex justify-between items-center'>
                    <button onClick={handleLogOut} className='flex gap-4 items-center'>
                        <MdLogout size={25} className={"text-black dark:text-[#ADBDDB]"} />
                        <h5 className='text-black font-bold dark:text-[#ADBDDB]'>{t("Log Out")}</h5>
                    </button>
                    <div>
                        <FaChevronRight className={"text-black dark:text-[#ADBDDB]"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile