import { ConfigProvider, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import EndTimePicker from './endTime/EndTimePicker';
import "./events.css";
import dayjs from 'dayjs';
import StartTimePicker from './startTime/StartTimePicker';
import { useAppContext } from '../context/Context';
import { useTranslation } from 'react-i18next';
import { toast } from '../../lib/toastFun';
import { updateTimeTrackingAPI } from '../../lib/apis';

const EditEventsDrawer = ({ editOffTimeObj, fetchHolidayIllness, setOpen, setEndDate, endDate }) => {
    const { theme } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const [selectedState, setSelectedState] = useState("holiday-payed");
    const [startDate, setStartDate] = useState();
    const [startTimeAntD, setStartTimeAntD] = useState(null);
    const [endTimeAntD, setEndTimeAntD] = useState(null);
    const [isEdited, setIsEdited] = useState(false);

    const onChangeStartDate = (time) => {
        setStartTimeAntD(time);
        setIsEdited(true);
    };

    const onChangeEndDate = (time) => {
        setEndTimeAntD(time);
        setIsEdited(true);
    };

    const handleChange = (value) => {
        setSelectedState(value);
        setIsEdited(true);
    };
    console.log(selectedState, "value")

    const handleEdit = async () => {
        if (!endDate || !endTimeAntD || !startTimeAntD || !startDate) {
            toast("error", "Please fill out all the fields");
            return;
        }

        function formatDateString(dateString) {
            // Parse the input date string
            const date = new Date(dateString);

            // Get the year, month, and day
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
            const day = date.getDate().toString().padStart(2, '0');

            // Format the date as "YYYY-MM-DD"
            const formattedDate = `${year}-${month}-${day}`;

            return formattedDate;
        }


        let start = typeof startDate === "string" ? startDate?.includes("T") ? startDate.split("T")[0] : startDate : formatDateString(startDate?.$d);
        let end = typeof endDate === "string" ? endDate?.includes("T") ? endDate.split("T")[0] : endDate : formatDateString(endDate?.$d);

        if (start > end) {
            toast("error", "Start date cannot be later than end date");
            return;
        }

        function converTime(time, date) {
            const timePart = time.split(" ")[4];
            return `${date}T${timePart}.000Z`;
        }
        let startTime = converTime(String(startTimeAntD.$d), String(start));
        let endTime = converTime(String(endTimeAntD.$d), String(end));

        let result = {
            startTime: startTime.slice(0, -1),
            endTime: endTime.slice(0, -1),
            startdate: start,
            enddate: end,
            type: selectedState,
            state: "open",
            mode: "mobile",
        };

        console.log("result to send", result);

        await updateTimeTrackingAPI(result, editOffTimeObj._id)
            .then((res) => {
                setIsLoading(false);
                toast("success", t("Successfully Updated!"));
                fetchHolidayIllness();
                setEndTimeAntD(null);
                setStartTimeAntD(null);
                setOpen(false);
            })
            .catch((err) => {
                toast("error", err?.response?.data?.error || "Error while updating the off time");
                console.log(err);
            })
            .finally(() => setIsLoading(false));
        setIsLoading(false);
    };

    function getTimeFromISOString(isoString) {
        const date = new Date(isoString);
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const timeString = `${hours}:${minutes}`;
        return timeString;
    }

    useEffect(() => {
        const initializeValues = () => {
            const startDateOnly = editOffTimeObj.startTime.split("T")[0];
            const endDateOnly = editOffTimeObj.endTime.split("T")[0];
            setStartDate(startDateOnly);
            setEndDate(endDateOnly);
            const startTime = getTimeFromISOString(editOffTimeObj.startTime);
            const endTime = getTimeFromISOString(editOffTimeObj.endTime);

            function convertTimeToISO8601(timeStr) {
                let currentDate = new Date();
                let [hours, minutes] = timeStr.split(":").map(Number);
                currentDate.setHours(hours);
                currentDate.setMinutes(minutes);
                return currentDate.toISOString();
            }

            const start = convertTimeToISO8601(startTime);
            setStartTimeAntD(dayjs(start));
            const end = convertTimeToISO8601(endTime);
            setEndTimeAntD(dayjs(end));
        };

        initializeValues();

        return () => {
            initializeValues();
        };
    }, [editOffTimeObj]);

    useEffect(() => {
        if (editOffTimeObj) {
            const startDate = dayjs(editOffTimeObj.startTime);
            const endDate = dayjs(editOffTimeObj.endTime);

            setStartDate(startDate);
            setEndDate(endDate);
            setStartTimeAntD(startDate);
            setEndTimeAntD(endDate);
            setSelectedState(editOffTimeObj.type)
        }
    }, [editOffTimeObj]);

    const disabledDate = (current) => {
        return current && current < dayjs().startOf('day');
    };
    return (
        <div className="flex h-fit gap-5 flex-col px-1 py-4">
            <h1 className=" dark:text-white text-black text-center font-bold text-3xl">
                {t("Off Time Editor")}
            </h1>
            <div className="flex gap-3 justify-between">
                <div className="flex flex-col items-center justify-center">
                    <p className="dark:text-white text-black whitespace-nowrap">
                        {t("Start Date")}
                    </p>
                    <div className="start-date-picker dark:start-date-picker end-date">
                        <DatePicker
                            inputReadOnly={true}
                            suffixIcon={false}
                            placement="topLeft"
                            popupClassName="smallCalendar start-dates"
                            disabledDate={disabledDate}
                            value={dayjs(startDate)}
                            onChange={(val) => {
                                setStartDate(val?.format("YYYY-MM-DD"));
                                setIsEdited(true); // Mark as edited when user changes start date
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <p className="dark:text-white text-black whitespace-nowrap">
                        {t("End Date")}
                    </p>
                    <div className="start-date-picker dark:start-date-picker end-date">
                        <DatePicker
                            inputReadOnly={true}
                            format="YYYY-MM-DD"
                            suffixIcon={false}
                            popupClassName="smallCalendar end-dates"
                            disabledDate={disabledDate}
                            dropdownClassName="customDropdown"
                            value={dayjs(endDate)}
                            onChange={(val) => {
                                setEndDate(val?.format("YYYY-MM-DD"));
                                setIsEdited(true); // Mark as edited when user changes end date
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <p className="dark:text-white text-black whitespace-nowrap">
                        {t("Start Time")}
                    </p>
                    <div className="start-time-picker">
                        <StartTimePicker
                            value={startTimeAntD}
                            dropdownClassName="start-time-picker"
                            onChange={onChangeStartDate}
                        />
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <p className="dark:text-white text-black whitespace-nowrap">
                        {t("End Time")}
                    </p>
                    <div className="end-time-picker ">
                        <EndTimePicker value={endTimeAntD} onChange={onChangeEndDate} />
                    </div>
                </div>
            </div>
            <div className="flex items-center gap-4">
                <h1 className="dark:text-white text-black">{t("State")}</h1>
                <ConfigProvider
                    theme={{
                        token: {
                            selectorBg: theme === "light" ? "" : "#15292B",
                            colorText: theme === "light" ? "#000" : "#fff",
                            selectorBg: theme === "light" ? "#fff" : "#000",
                            colorBgContainer: theme === "light" ? "#fff" : "#15292B",
                        },
                    }}>
                    <Select
                        defaultValue={t("Holiday Payed")}
                        className="off-time-option"
                        style={{ width: "100%" }}
                        value={selectedState}
                        onChange={handleChange}
                        size="large"
                        options={[
                            { value: "holiday-payed", label: t("Holiday Payed") },
                            { value: "holiday-unpayed", label: t("Holiday Unpayed") },
                            { value: "illness", label: t("Illness") },
                            { value: "school", label: t("School") },
                            { value: "miscellaneous", label: t("Miscellaneous") },
                            { value: "illness-child", label: t("Illness child") },
                        ]}
                    />
                </ConfigProvider>
            </div>
            <button disabled={isLoading} onClick={handleEdit} className="bg-[#540ae6] text-white text-[16px] w-full rounded-lg py-2 px-3 shadow-2xl text-center">
                {isEdited ? t("Update") : t("Edit")}
            </button>
        </div>
    );
};

export default EditEventsDrawer;