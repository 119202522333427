import React, { useEffect, useState } from 'react'
import Calendar from '../components/schedule/Calander'
import Tabs from '../components/Tabs'
import MusicCard from '../components/events/MusicCard'
import { IoMdAdd } from "react-icons/io";
import EventDrawer from '../components/drawer/EventDrawer';
import { getEmployeeDetailsAPI, getEmployeeHolidayIllnessesAPI, getEmployeeTimeTracking, getSettings } from '../lib/apis';
import { useTranslation } from 'react-i18next';
import { toast } from '../lib/toastFun';
import { ConfigProvider, Drawer } from 'antd';
import { useAppContext } from '../components/context/Context';

const tabs = [
    {
        id: 1,
        name: "All",
        color: "text-[#0000FF] dark:text-[#7878ff]"
    },
    {
        id: 2,
        name: "Holiday",
        color: "text-[#008000]"
    },
    {
        id: 3,
        name: "Illness",
        color: "text-[#FF0000]"
    },
    {
        id: 4,
        name: "Others",
        color: "text-[#FF0000]"
    },
]

const Events = () => {
    const { t } = useTranslation()
    const [tabName, setTabName] = useState("All");
    const { theme } = useAppContext();


    const [timeRate, setTimeRate] = useState({
        opened: 0,
        confirmed: 0,
    });


    const [timeRates, setTimeRates] = useState({

        holidayPayed: 0,
        holidayUnPayed: 0,
        illessChildTime: 0,
        illessTime: 0,

    });
    const [holidayIllnessArr, setHolidayIllnessArr] = useState([])
    const [timeTrackings, setTimeTrackings] = useState();
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [filteredHolidayIllnessArr, setFilteredHolidayIllnessArr] = useState([]);
    const [allowedEnterOffTimes, setallowedOfftime] = useState();
    const [holiday, setHoliday] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false);


    const [selectedState, setSelectedState] = useState("holiday-payed")
    const [endDateAntD, setEndDateAntD] = useState();
    const [startDateAntD, setStartDateAntD] = useState();
    const [startTimeAntD, setStartTimeAntD] = useState(null);
    const [endTimeAntD, setEndTimeAntD] = useState(null);


    const onClose = () => {
        setEndTimeAntD(null)
        setStartTimeAntD(null)
        setStartDateAntD()
        setEndDateAntD()
        setOpen(false);
    }


    const calcTimeRange = (startTime, endTime) => {
        const startDate = new Date(`1970-01-01T${startTime}:00Z`);
        const endDate = new Date(`1970-01-01T${endTime}:00Z`);

        let diff = endDate - startDate;

        if (diff < 0) {
            diff += 24 * 60 * 60 * 1000;
        }
        const hours = Math.floor(diff / (1000 * 60 * 60));
        return hours;
    };

    const filterData = (tab) => {
        switch (tab) {
            case "Others":
                setFilteredHolidayIllnessArr(holidayIllnessArr.filter(
                    (holiday) => holiday.type !== "holiday-payed" && holiday.type != "illness"
                ));
                break;
            case "Holiday":
                setFilteredHolidayIllnessArr(holidayIllnessArr.filter(
                    (holiday) => holiday.type === "holiday-payed"
                ));
                break;
            case "Illness":
                setFilteredHolidayIllnessArr(holidayIllnessArr.filter(
                    (holiday) => holiday.type === "illness"
                ));
                break;
            default:
                setFilteredHolidayIllnessArr(holidayIllnessArr);
        }
    };

    const fetchHolidayIllness = () => {
        setIsLoading(true)
        getEmployeeHolidayIllnessesAPI().then(res => {
            let illness = res.data;
            illness = res.data.filter((item) => {
                const itemEndDate = new Date(item.endTime); // Convert string date to Date object

                // Compare only the year and month part of the dates
                return (
                    itemEndDate.getFullYear() === selectedYear &&
                    itemEndDate.getMonth() - 1 === (selectedMonth - 1) // Adjust month to zero-indexed
                );
            });

            setHolidayIllnessArr(illness);
            getSettings().then((response) => {
                setallowedOfftime(response.data.allowedEnterOffTimes);
            });
            let illessTime = 0;
            let illessChildTime = 0;
            let holidayPayed = 0;
            let holidayUnPayed = 0;
            getEmployeeDetailsAPI(illness[0]?.employee?._id).then((response) => {
                illessTime = Number(response.data.illness) || 0;
                illessChildTime = Number(response.data.illnesschild) || 0;
                holidayPayed = Number(response.data.holidaypayed) || 0;
                holidayUnPayed = Number(response.data.holidayunpayed) || 0;
                setTimeRates({
                    holidayPayed,
                    holidayUnPayed,
                    illessChildTime,
                    illessTime,
                });
            }).catch(err => console.log(err, "error in details"));
            setIsLoading(false)
        }).catch(err => console.log(err)).finally(() => setIsLoading(false))
    }




    useEffect(() => {
        setIsLoading(true)
        getEmployeeTimeTracking().then((res) => {
            let trackings = res.data;
            if (res.data.length) {
                trackings = res.data.filter((item) => {
                    const date = new Date(item.date);
                    return (
                        date.getFullYear() == selectedYear &&
                        date.getMonth() == selectedMonth
                    );
                });
            }

            setTimeTrackings(trackings);

            let openedTime = 0;
            let confirmedTime = 0;

            if (trackings.length) {
                trackings.map((tracking) => {
                    if (tracking.confirmedByEmployee) {
                        confirmedTime += calcTimeRange(
                            tracking.startTime,
                            tracking.endTime
                        );
                    } else {
                        openedTime += calcTimeRange(tracking.startTime, tracking.endTime);
                    }
                });
            }

            setTimeRate({
                opened: openedTime,
                confirmed: confirmedTime,
            });
        }).catch((err) => console.error(err));

        getEmployeeHolidayIllnessesAPI().then(res => {
            console.log(res.data, "holiday illness")
            let illness = res.data;
            illness = res.data.filter((item) => {
                const itemEndDate = new Date(item.endTime); // Convert string date to Date object

                // Compare only the year and month part of the dates
                return (
                    itemEndDate.getFullYear() === selectedYear &&
                    itemEndDate.getMonth() - 1 === (selectedMonth - 1) // Adjust month to zero-indexed
                );
            });

            setHolidayIllnessArr(illness);
            getSettings().then((response) => {
                setallowedOfftime(response.data.allowedEnterOffTimes);
            }).catch(err => console.log(err));
            let illessTime = 0;
            let illessChildTime = 0;
            let holidayPayed = 0;
            let holidayUnPayed = 0;
            getEmployeeDetailsAPI(illness[0]?.employee?._id).then((response) => {
                console.log("response.data.illness", response.data.illness)
                illessTime = Number(response.data.illness) || 0;
                illessChildTime = Number(response.data.illnesschild) || 0;
                holidayPayed = Number(response.data.holidaypayed) || 0;
                holidayUnPayed = Number(response.data.holidayunpayed) || 0;
                setTimeRates({
                    holidayPayed,
                    holidayUnPayed,
                    illessChildTime,
                    illessTime,
                });
            }).catch(err => console.log(err, "error in details"));
            setIsLoading(false)
        }).catch(err => console.log(err)).finally(() => setIsLoading(false))
    }, [selectedMonth, selectedYear]);

    const OpenDrawer = () => {
        if (allowedEnterOffTimes) {
            setOpen(true)
        } else {
            toast("error", "Feature is not activated at the moment. Please ask your Workspace Admin for further questions. Thanks.")
        }
    }

    useEffect(() => {
        filterData(tabName);
    }, [tabName, holidayIllnessArr]);


    return (
        <>
            <div className='flex flex-col gap-5' >
                <div className='flex items-center justify-between text-center'>
                    <div className='p-3 text-[12px] w-fit rounded-lg bg-[#E2EAFF] dark:bg-[#2E4682] dark:text-white'>
                        <h4>{t("Sum")}:</h4>
                        <p>{timeRates.holidayPayed}h: {t("holiday payed")}</p>
                        <p>{timeRates.holidayUnPayed}h: {t("holiday unpayed")}</p>
                        <p>{timeRates.illessTime}h: {t("Illness")}</p>
                        <p>{timeRates.illessChildTime}h: {t("Illness Child")}</p>
                    </div>
                    <div>
                        <Calendar setSelectedYear={setSelectedYear} setSelectedMonth={setSelectedMonth} />
                    </div>
                </div>
                <div>
                    <Tabs tabName={tabName} setTabName={setTabName} tabs={tabs} />
                </div>
                {
                    isLoading ? (
                        <div className='w-full h-full flex justify-center items-center'>
                            <img src='/images/loader2.svg' className='w-20' />
                        </div>
                    ) :
                        <div className='flex flex-col relative gap-3 h-[calc(100svh-340px)] lg:h-auto overflow-y-scroll'>

                            {
                                filteredHolidayIllnessArr?.length ? filteredHolidayIllnessArr?.map(ev => (
                                    <MusicCard fetchHolidayIllness={fetchHolidayIllness} key={ev._id} data={ev} />
                                )) : (
                                    <p className='text-center text-gray-500 font-semibold text-[14px]'>There is no data to dispay</p>
                                )
                            }
                            <button onClick={OpenDrawer} className="bg-[#540ae6] p-3 rounded-full fixed z-[200] bottom-14 right-3">
                                <IoMdAdd size={20} color="white" />
                            </button>
                            {
                                allowedEnterOffTimes && (
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorBgElevated: theme === "light" ? "#fff" : "#15292B",
                                            },
                                        }}>
                                        <Drawer
                                            placement={"bottom"}
                                            closable={false}
                                            onClose={onClose}
                                            open={open}
                                            key={"bottom"}
                                            // mask={false}
                                            height={"auto"}
                                            style={{ padding: 0 }}>
                                            <EventDrawer setStartDateAntD={setStartDateAntD} startDateAntD={startDateAntD} setStartTimeAntD={setStartTimeAntD} setEndDateAntD={setEndDateAntD} endDateAntD={endDateAntD} startTimeAntD={startTimeAntD} setEndTimeAntD={setEndTimeAntD} endTimeAntD={endTimeAntD} setOpen={setOpen} fetchHolidayIllness={fetchHolidayIllness} />
                                        </Drawer>
                                    </ConfigProvider>
                                )
                            }
                        </div>
                }

            </div>
        </>
    )
}

export default Events