import { DatePicker } from "antd";
import dayjs from "dayjs";
import "./editshift.css";
import React, { useEffect, useState } from "react";
import EndTimePicker from "./endTime/EndTimePicker";
import StartTimePicker from "./startTime/StartTimePicker";
import { updateTimeForEmployee } from "../../lib/apis";
import { toast } from "../../lib/toastFun";
import { useTranslation } from "react-i18next";
const EditShiftDrawer = ({
    date,
    setDummyOpen,
    startTime,
    endTime,
    id,
    fetchEmployeeTimeTracking,
}) => {
    const [selectedDate, setSelectedDate] = useState();

    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation()

    // To Formate the AntD date in backend date

    function formatTime(dateString) {
        // Create a new Date object with the given date string
        const date = new Date(dateString);

        // Extract hours and minutes
        const hours = date.getHours();
        const minutes = date.getMinutes();

        // Format hours and minutes with leading zeros if needed
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        // Combine hours and minutes into the desired format
        const formattedTime = `${formattedHours}:${formattedMinutes}`;

        return formattedTime;
    }

    // Time Component Of AntD
    const [startTimeAntD, setStartTimeAntD] = useState(null);
    const onChangeStartDate = (time) => {
        setStartTimeAntD(time);
    };
    const [endTimeAntD, setEndTimeAntD] = useState(null);
    const onChangeEndDate = (time) => {
        setEndTimeAntD(time);
        console.log("endTimeAntD", endTimeAntD);
    };

    const updateTimeFunction = async () => {
        console.log({
            endTimeAntD,
            startTimeAntD,

        })
        setIsLoading(true)
        try {
            setIsLoading(true)
            const formattedStartTime = formatTime(startTimeAntD.$d) || startTime;
            const formattedEndTime = formatTime(endTimeAntD.$d) || endTime;

            if (formattedStartTime >= formattedEndTime) {
                toast("error", "Start time must be earlier than end time.");
                return;
            }

            // console.log({ formattedStartTime, formattedEndTime, selectedDate })

            // console.log(id, "edit id")

            const res = await updateTimeForEmployee(id, {
                date: selectedDate,
                startTime: formattedStartTime,
                endTime: formattedEndTime,
            });
            setDummyOpen(false);
            fetchEmployeeTimeTracking();
            setIsLoading(false)
        } catch (error) {
            console.log(error, "error while updating");
            toast("error", "error while updating");
        }
    };

    useEffect(() => {
        setSelectedDate(date)
        function convertTimeToISO8601(timeStr) {
            // Get current date
            let currentDate = new Date();

            // Split the time string into hours and minutes
            let [hours, minutes] = timeStr.split(":").map(Number);

            // Set the hours and minutes to the current date
            currentDate.setHours(hours);
            currentDate.setMinutes(minutes);

            // Format the date in ISO 8601 format
            let iso8601Format = currentDate.toISOString();

            // Return the ISO 8601 formatted string
            return iso8601Format;
        }

        const convertedTime = convertTimeToISO8601(startTime);
        setStartTimeAntD(dayjs(convertedTime));
        const convertedEndTime = convertTimeToISO8601(endTime);
        setEndTimeAntD(dayjs(convertedEndTime));

        return () => {
            setStartTimeAntD(null);
            setEndTimeAntD(null);
            setSelectedDate()
        }
    }, []);


    return (
        <>
            <div className="flex flex-col gap-6 px-2 py-6">
                <h1 className="text-2xl font-bold text-center text-gray-500 dark:text-white">
                    {t("Time Tracking Editor")}
                </h1>
                <div className="flex gap-4 justify-around ">
                    <div className="flex flex-col gap-[2px] items-center">
                        <p className="dark:text-white">{t("Date")}</p>
                        <div className="start-time-picker dark:start-time-picker">
                            <DatePicker
                                inputReadOnly={true}
                                placement="topLeft"
                                value={dayjs(selectedDate)}
                                onChange={(val) => {
                                    // const date = new Date(val);
                                    // const formattedDate = date.toISOString().split("T")[0];
                                    setSelectedDate(val?.format("YYYY-MM-DD"));
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-[2px] items-center">
                        <p className="dark:text-white">{t("Start-time")}</p>
                        <div className="start-time-picker">
                            <StartTimePicker
                                value={startTimeAntD}
                                onChange={onChangeStartDate}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-[2px] items-center">
                        <p className="dark:text-white">{t("End-time")}</p>
                        <div className="end-time-picker">
                            <EndTimePicker value={endTimeAntD} onChange={onChangeEndDate} />
                        </div>
                    </div>
                </div>
                <div

                >
                    <button
                        onClick={updateTimeFunction}
                        disabled={isLoading}
                        className={`${isLoading ? "bg-[#3a0970]" : "bg-[#8757FB]"
                            } w-full rounded-lg py-2 px-3 shadow-2xl text-center text-white text-[16px] `}
                    >
                        {isLoading ? "Updating" : t("Update")}
                    </button>
                </div>
            </div>
        </>
    );
};

export default EditShiftDrawer;
