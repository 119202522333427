import React, { useEffect, useState } from "react";
import { FiUser } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { getEmployeeSettings } from "../lib/apis";
import Header from "../components/shared/Header";
import { useTranslation } from "react-i18next";
const ProfileSettings = () => {
  const { t } = useTranslation();
  const [employeeDetails, setEmployeeDetails] = useState({});
  const fetchEmployeerSettings = async () => {
    try {
      const res = await getEmployeeSettings();

      setEmployeeDetails(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchEmployeerSettings();
  }, []);
  return (
    <div className='flex flex-col h-[100svh] dark:bg-[#15292B]'>
      <Header path={"/profile/accountSettings"} />
      <div className='max-h-[calc(100svh-70px)] overflow-y-auto'>
        <div className='flex p-4 flex-col gap-3 overflow-auto'>
          <div className='flex gap-1 flex-col '>
            <label className='text-black dark:text-[#4C5D6A]' htmlFor=''>
              {t("First Name")}
            </label>
            <div className='p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2'>
              <div className='bg-[#8757FB] rounded-lg p-2'>
                <FiUser color={"white"} size={30} />
              </div>
              <input
                disabled
                type='text'
                className='w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none'
                value={employeeDetails?.firstName}
              />
            </div>
          </div>
          <div className='flex gap-1 flex-col'>
            <label className='text-black  dark:text-[#4C5D6A]' htmlFor=''>
              {t("Last Name")}
            </label>
            <div className='p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2'>
              <div className='bg-[#8757FB] rounded-lg p-2'>
                <FiUser color={"white"} size={30} />
              </div>
              <input
                disabled
                type='text'
                className='w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none'
                value={employeeDetails?.lastName}
              />
            </div>
          </div>
          <div className='flex gap-1 flex-col'>
            <label className='text-black dark:text-[#4C5D6A]' htmlFor=''>
              {t("Email Address")}
            </label>
            <div className='p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2'>
              <div className='bg-[#8757FB] rounded-lg p-2'>
                <MdOutlineMailOutline color={"white"} size={30} />
              </div>
              <input
                disabled
                type='email'
                className='w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none'
                value={employeeDetails?.email}
              />
            </div>
          </div>
          <div className='flex gap-1 flex-col'>
            <label className='text-black dark:text-[#4C5D6A]' htmlFor=''>
              {t("Role")}
            </label>
            <div className='p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2'>
              <div className='bg-[#8757FB] rounded-lg p-2'>
                <FiUser color={"white"} size={30} />
              </div>
              <input
                disabled
                type='text'
                className='w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none'
                value={employeeDetails?.role}
              />
            </div>
          </div>
          <div className='flex gap-1 flex-col'>
            <label className='text-black dark:text-[#4C5D6A]' htmlFor=''>
              {t("Work Time week")}
            </label>
            <div className='p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2'>
              <div className='bg-[#8757FB] rounded-lg p-2'>
                <FiUser color={"white"} size={30} />
              </div>
              <input
                disabled
                type='text'
                className='w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none'
                value={employeeDetails?.workingTimeWeek}
              />
            </div>

          </div>
          <div className='flex gap-1 flex-col'>
            <label className='text-black dark:text-[#4C5D6A]' htmlFor=''>
              {t("Work Time Month")}
            </label>
            <div className='p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2'>
              <div className='bg-[#8757FB] rounded-lg p-2'>
                <FiUser color={"white"} size={30} />
              </div>
              <input
                disabled
                type='text'
                className='w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none'
                value={employeeDetails?.workingTimeMonth}
              />
            </div>
          </div>
          <div className='flex gap-1 flex-col'>
            <label className='text-black dark:text-[#4C5D6A]' htmlFor=''>
              {t("Holiday this year")}
            </label>
            <div className='p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2'>
              <div className='bg-[#8757FB] rounded-lg p-2'>
                <FiUser color={"white"} size={30} />
              </div>
              <input
                disabled
                type='text'
                className='w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none'
                value={`${employeeDetails?.holidayThisYear} hours `}
              />
            </div>

          </div>
          <div className='flex gap-1 flex-col'>
            <label className='text-black dark:text-[#4C5D6A]' htmlFor=''>
              {t("Holiday Contact")}
            </label>
            <div className='p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2'>
              <div className='bg-[#8757FB] rounded-lg p-2'>
                <FiUser color={"white"} size={30} />
              </div>
              <input
                disabled
                type='text'
                className='w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none'
                value={`${employeeDetails?.holidayContacts} days per Year`}
              />
            </div>

          </div>
          <div className='flex gap-1 flex-col'>
            <label className='text-black dark:text-[#4C5D6A]' htmlFor=''>
              {t("Amount Of Confirmed Overtaking Requests")}
            </label>
            <div className='p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2'>
              <div className='bg-[#8757FB] rounded-lg p-2'>
                <FiUser color={"white"} size={30} />
              </div>
              <input
                disabled
                type='text'
                className='w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none'
                value={`${employeeDetails?.overTackings} of this year `}
              />
            </div>

          </div>
          <div className='flex gap-1 flex-col'>
            <label className='text-black dark:text-[#4C5D6A]' htmlFor=''>
              {t("Amount Of Declined Overtaking Requests")}
            </label>
            <div className='p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2'>
              <div className='bg-[#8757FB] rounded-lg p-2'>
                <FiUser color={"white"} size={30} />
              </div>
              <input
                disabled
                type='text'
                className='w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none'
                value={`${employeeDetails?.DeclinedoverTackings} of this year `}
              />
            </div>

          </div>
          <div className='flex gap-1 flex-col'>
            <label className='text-black dark:text-[#4C5D6A]' htmlFor=''>
              {t("Sickness This Year Employee")}
            </label>
            <div className='p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2'>
              <div className='bg-[#8757FB] rounded-lg p-2'>
                <FiUser color={"white"} size={30} />
              </div>
              <input
                disabled
                type='text'
                className='w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none'
                value={`${employeeDetails?.sicknessEmployee} hours `}
              />
            </div>
          </div>
          <div className='flex gap-1 flex-col'>
            <label className='text-black dark:text-[#4C5D6A]' htmlFor=''>
              {t("Sickness This Year (Child & Employee)")}
            </label>
            <div className='p-2 flex gap-2 w-full border border-[#ADBDDB] dark:border-white rounded-xl py-2'>
              <div className='bg-[#8757FB] rounded-lg p-2'>
                <FiUser color={"white"} size={30} />
              </div>
              <input
                disabled
                type='text'
                className='w-full px-3 dark:text-[#ADBDDB] bg-transparent text-black outline-none'
                value={`${employeeDetails?.sicknessHours} hours this year(illness & illness child) `}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
