import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineWbSunny } from "react-icons/md";
import { IoMdArrowBack } from "react-icons/io";
import { IoMoonOutline } from "react-icons/io5";
import { useAppContext } from "../context/Context";
const Header = ({ path }) => {
  const { theme, setTheme } = useAppContext();
  const toggleDarkMode = () => {
    const htmlTag = document.querySelector("html");
    htmlTag.classList.toggle("dark");
    setTheme(theme === "light" ? "dark" : "light");
  };
  return (
    <div className=" dark:bg-[#15292B] flex justify-between items-center px-3 h-20">
      <Link to={path}>
        {theme === "light" ? (
          <IoMdArrowBack size={20} color="#540ae6" className="" />
        ) : (
          <IoMdArrowBack size={20} color="#fff" className="" />
        )}
      </Link>
      <div className="flex items-center">
        <img className="w-[70px] dark:invert" src="/images/roster_logo.png" alt="" />
      </div>
      <button onClick={toggleDarkMode} className="">
        {theme === "light" ? (
          <IoMoonOutline size={20} />
        ) : (
          <MdOutlineWbSunny size={20} color="#fff" />
        )}
      </button>
    </div>
  );
};

export default Header;
