import React, { useEffect, useState } from "react";
import ScheduleTabs from "../components/schedule/ScheduleTabs";
import Calendar from "../components/schedule/Calander";
import {
  confirmByEmployee,
  getEmployeeTimeTracking,
  getSettings,
} from "../lib/apis";
import ScheduleCard from "../components/schedule/ScheduleCard";
import { toast } from "../lib/toastFun";

const Schedule = () => {
  const [tabName, setTabName] = useState("All");
  const [employeeTime, setEmployeeTime] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [filteredEmployeeTime, setFilteredEmployeeTime] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [confirmBtnDisable, setConfirmBtnDisable] = useState(false)
  const [editShifts, setEditShifts] = useState(false);
  const [editId, setEditId] = useState("");

  const fetchEmployeeTimeTracking = async () => {
    try {
      setIsLoading(true)
      const res = await getEmployeeTimeTracking();
      let timetracking = res.data;
      timetracking = res.data.filter((item) => {
        const itemEndDate = new Date(item.date);
        // Convert string date to Date object

        // Compare only the year and month part of the dates
        return (
          itemEndDate.getFullYear() === selectedYear &&
          itemEndDate.getMonth() - 1 === selectedMonth - 1 // Adjust month to zero-indexed
        );
      });
      setEmployeeTime(timetracking);
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  };

  const fetchSettings = async (id) => {
    setEditId(id);
    try {
      const res = await getSettings();
      if (res?.data?.activateFeatureTimeTracking) {
        setEditShifts(true);
      } else {
        setEditShifts(false);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const confirmEmployee = async (id) => {
    try {
      setConfirmBtnDisable(true)
      const res = await confirmByEmployee(id);
      toast("success", " Your time tracking was successful confirmed 👍")
      fetchEmployeeTimeTracking();
      setConfirmBtnDisable(false)
    } catch (error) {
      setConfirmBtnDisable(false)
      console.log(error);
    }
  };

  const filterData = (tab) => {
    switch (tab) {
      case "Opened":
        setFilteredEmployeeTime(
          employeeTime.filter((employee) => !employee.confirmedByEmployee)
        );
        break;
      case "Confirmed":
        setFilteredEmployeeTime(
          employeeTime.filter((employee) => employee.confirmedByEmployee)
        );
        break;
      default:
        setFilteredEmployeeTime(employeeTime);
    }
  };

  useEffect(() => {
    fetchEmployeeTimeTracking();
    fetchSettings();
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    filterData(tabName);
  }, [tabName, employeeTime]);

  return (
    <>
      <div className="flex flex-col relative ">
        <Calendar
          setSelectedYear={setSelectedYear}
          setSelectedMonth={setSelectedMonth}
        />
        <ScheduleTabs tabName={tabName} setTabName={setTabName} />
        {
          isLoading ? (
            <div className='w-full h-full flex justify-center items-center'>
              <img src='/images/loader2.svg' className='w-20' />
            </div>
          ) : (

            <div className="bg-white dark:bg-[#15292B] dark:text-white flex flex-col gap-6 h-[calc(100svh-215px)] lg:h-auto overflow-y-scroll ">
              {
                filteredEmployeeTime?.length ? filteredEmployeeTime.map((employee, i) => (
                  <ScheduleCard
                    key={i}
                    employee={employee}
                    setEditId={setEditId}
                    editId={editId}
                    setEditShifts={setEditShifts}
                    confirmBtnDisable={confirmBtnDisable}
                    setConfirmBtnDisable={setConfirmBtnDisable}
                    editShifts={editShifts}
                    confirmEmployee={confirmEmployee}
                    fetchSettings={fetchSettings}
                    fetchEmployeeTimeTracking={fetchEmployeeTimeTracking}
                  />
                )) : (
                  <p className='text-center text-gray-500 font-semibold text-[14px]'>There is no data to display</p>
                )}
            </div>
          )

        }
      </div>
    </>
  );
};

export default Schedule;
