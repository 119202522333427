import { message } from 'antd';

let toastApi = null;

export const setToast = () => {
   const [messageApi, contextHolder] = message.useMessage();
   toastApi = messageApi;

   return { contextHolder }
}

export const toast = (type = 'info', content = "", duration = 3) => {
   if (!toastApi) return;
   toastApi.open({ type, content, duration });
}