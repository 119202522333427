import React, { useState } from 'react'
import CustomDrawer from '../CustomDrawer';
import { IoIosCloseCircleOutline } from "react-icons/io";
import './music.css'
import { Modal } from 'antd';
import { IoInformationCircle } from "react-icons/io5";
import { deleteHolidayIllnessAPI, updateHolidayIllnessAPI } from '../../lib/apis';
import { useTranslation } from 'react-i18next';
import EditEventsDrawer from '../drawer/EditEventsDrawer';
import { toast } from '../../lib/toastFun';

const MusicCard = ({ data, fetchHolidayIllness }) => {
    const { t } = useTranslation()
    const [selectedId, setSelectedId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false)
    const [deleteBtnDisable, setDeleteBtnDisable] = useState(false)
    const [confirmedModal, setConfirmedModal] = useState(false)
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [editOffTimeObj, setEditOffTimeObj] = useState({})
    function formatDateString(inputDateString) {
        // Extract date and time parts
        const [datePart, timePart] = inputDateString.split('T');
        const [year, month, day] = datePart.split('-');
        const [hours, minutes] = timePart.split(':');

        // Construct the formatted date string
        const formattedDateString = `${day}.${month}.${year} ${hours}:${minutes}`;

        return formattedDateString;
    }


    const [endDate, setEndDate] = useState(null)


    // Will work new logic

    // function formatDate(inputDate) {
    //     // Parse the input ISO 8601 date-time string
    //     const date = new Date(inputDate);

    //     // Add 2 hours to the date
    //     date.setUTCHours(date.getUTCHours() + 2);

    //     // Extract date components
    //     const day = ('0' + date.getUTCDate()).slice(-2);
    //     const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
    //     const year = date.getUTCFullYear();

    //     // Extract time components
    //     const hours = ('0' + date.getUTCHours()).slice(-2);
    //     const minutes = ('0' + date.getUTCMinutes()).slice(-2);

    //     // Combine date and time parts
    //     const formattedDateTime = `${day}.${month}.${year} ${hours}:${minutes}`;
    //     return formattedDateTime;
    // }


    const handleConfirm = (id) => {
        updateHolidayIllnessAPI(id, { state: "approved", mode: "mobile" })
            .then((res) => {
                setConfirmedModal(true)
                fetchHolidayIllness();
                setDrawerOpen(false);

            })
            .catch(console.log);
    };
    const handleDelete = (id) => {
        setDeleteBtnDisable(true)
        deleteHolidayIllnessAPI(id).then(res => {
            console.log(res);
            toast("success", "Off Time deleted successfully")
            fetchHolidayIllness();
            setDrawerOpen(false)
        }).catch(err => {
            console.log(err);
            setDeleteBtnDisable(false)
        })
    };

    const editOffTimeFunc = async () => {
        setEditOffTimeObj(data)
    }

    const handleClose = () => {
        // setEndDate(null)
        setShowEditModal(false)
    }


    return (
        <div className='flex justify-between items-center'>
            <div className='flex gap-3 items-center'>

                <div className={`w-12 h-12 flex justify-center items-center ${data.state === "declined" ? "bg-[#FFDDE4] dark:bg-[#4A3B66] " : "bg-[#DEFBE9] dark:bg-[#2A5568]"}  rounded-lg`}>
                    {data.state === "declined" ? (
                        <img className='w-6' src="/images/redspotify.png" alt="" />
                    ) : (
                        <img className='w-24' src="/images/spotify.png" alt="" />
                    )}
                </div>
                <div className='text-[12px]'>
                    <p className={`font-bold ${data.state
                        === "approved" ? "text-[#4fa41d]" : data.state === "declined" ? "text-red-700" : " text-black dark:text-slate-400 "}`}>{t(data?.type)}</p>
                    <p className='text-gray-400'>from-{formatDateString(data.startTime)}</p>
                    <p className='text-gray-400'>to-{formatDateString(data.endTime)}</p>
                </div>
            </div>
            <div onClick={() => {
                if (data.state === "open") {
                    setSelectedId(data._id)
                    setEditOffTimeObj(data)
                    setDrawerOpen(true)
                } else {
                    return
                }
            }} className={`px-2 py-1 font-bold w-20 flex items-center justify-center ${data.state === "approved" ? "text-[#4fa41d] bg-[#ddf6ee]" : data.state === "declined" ? "text-red-700 bg-red-100" : "text-black bg-gray-300"}   rounded-lg text-[12px] `}>
                {t(data.state)}
            </div>
            <CustomDrawer onClose={() => setDrawerOpen(false)} open={drawerOpen}>
                <div className='py-3 px-3 flex flex-col gap-5'>
                    <h1 className='text-xl text-center dark:text-white'>{t("Off Time Editor")}</h1>
                    <div className='w-full flex gap-5'>
                        <button onClick={() => {
                            setShowEditModal(true)
                            editOffTimeFunc()
                            setDrawerOpen(false)
                        }} className='py-2 text-white w-full bg-green-800 rounded-md'>
                            {t("Edit")}
                        </button>
                        <button onClick={() => handleDelete(selectedId)} disabled={deleteBtnDisable} className='py-2 text-white  bg-red-400 w-full disabled:bg-red-400/60 rounded-md'>
                            {t("Delete")}
                        </button>
                    </div>
                </div>
            </CustomDrawer>
            <Modal className='!w-fit' styles={{ content: { padding: "0" } }}
                style={{ width: "auto" }} footer={false} open={confirmedModal} closeIcon={<IoIosCloseCircleOutline color='#9333EA' size={25} />} onCancel={() => setConfirmedModal(false)}>
                <div className='flex w-[300px] py-8 flex-col gap-2 items-center justify-center'>
                    <div>
                        <IoInformationCircle color='#9333EA' size={40} />
                    </div>
                    <div className='text-center'>
                        <p className='text-[15px] font-bold'>{t("Change Off Time")}</p>
                        <p className='text-[10px]'>{t("Change Time Updated!")}</p>
                    </div>
                    <button onClick={() => setConfirmedModal(false)} className='py-1 text-white w-fit  bg-purple-600 px-6 rounded-md'>
                        {t("OK")}
                    </button>
                </div>
            </Modal>
            <CustomDrawer onClose={handleClose} open={showEditModal}>
                <EditEventsDrawer endDate={endDate} setEndDate={setEndDate} setOpen={setShowEditModal} fetchHolidayIllness={fetchHolidayIllness} editOffTimeObj={editOffTimeObj} />
            </CustomDrawer>
        </div>
    )
}

export default MusicCard