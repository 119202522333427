import React, { useEffect } from 'react'
import { MdOutlineDateRange } from "react-icons/md";
import { GoStopwatch } from "react-icons/go";
import { TbClockOff } from "react-icons/tb";
import { IoMdSettings } from "react-icons/io";
import { MdLogout } from "react-icons/md";
import { MdSupportAgent } from "react-icons/md";
import { FaChevronRight, FaDropbox } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../context/Context';
import { useTranslation } from 'react-i18next';

const Sidebar = ({ open, setOpen }) => {
    const { theme } = useAppContext();
    const { userInfo } = useAppContext();
    const { userName, userId } = userInfo?.body?.userInfo
    const avatarWithUsername = userName.split(" ");
    const { t } = useTranslation()
    const Links = [
        {
            name: "Shift Plan",
            icon: <MdOutlineDateRange color={theme === "light" ? "#000" : "#ADBDDB"} size={15} />,
            path: "/"
        },
        {
            name: "Time Tracking",
            icon: <GoStopwatch color={theme === "light" ? "#000" : "#ADBDDB"} size={15} />,
            path: "/Schedule"
        },
        {
            name: "Off Times",
            icon: <TbClockOff color={theme === "light" ? "#000" : "#ADBDDB"} size={15} />,
            path: "/events"
        },
        {
            name: "Dropout Manager",
            icon: <FaDropbox color={theme === "light" ? "#000" : "#ADBDDB"} size={15} />,
            path: "/dropout"
        },
        {
            name: "Account Settings",
            icon: <IoMdSettings color={theme === "light" ? "#000" : "#ADBDDB"} size={15} />,
            path: "/profile/accountSettings"
        },
        {
            name: "Support",
            icon: <MdSupportAgent color={theme === "light" ? "#000" : "#ADBDDB"} size={15} />,
            path: "/support"
        },
    ]

    const navigate = useNavigate()
    const onClose = (path) => {
        navigate(path)
        setOpen(false);
    };
    const handleLogOut = () => {
        localStorage.removeItem("access_token");
        navigate("/auth/signIn");
    };
    return (
        <div className={"w-[70vw]  h-[100svh]"}>
            <div className='py-4 px-3 flex flex-col h-full gap-8 w-full'>
                <div className='flex items-center gap-4 '>
                    <div className='w-10 h-10 flex items-center justify-center rounded-full bg-[#6650A3]'>
                        <p className='text-[15px] text-white dark:text-[#fff]'>
                            {
                                avatarWithUsername?.length > 1 && `${avatarWithUsername[0]?.charAt(0)}${avatarWithUsername[1]?.charAt(0)}`
                            }
                        </p>
                    </div>
                    <div className=''>
                        <h4 className='text-black text-[14px] font-bold dark:text-[#ADBDDB]'>{userName}</h4>
                        <p className='text-black font-light text-[12px] dark:text-[#ADBDDB]'>{userId}</p>
                    </div>
                </div>
                <div className='flex flex-col h-full px-1  justify-between'>
                    <div className='flex flex-col gap-6 w-full pr-2'>
                        {
                            Links.map((link, i) => (
                                <div onClick={() => onClose(link.path)} key={i} className='flex items-center justify-between'>
                                    <div className='flex gap-3 dark:text-[#ADBDDB] items-center'>
                                        {link.icon}
                                        <span className='dark:text-[#ADBDDB]'>{t(link.name)}</span>
                                    </div>
                                    <div>
                                        <FaChevronRight color={theme === "light" ? "#000" : "#ADBDDB"} size={10} />
                                    </div>
                                </div>
                            ))
                        }

                        <div className='flex items-center justify-between mt-10 '>
                            <button onClick={handleLogOut} className='flex gap-3 dark:text-[#ADBDDB] items-center'>
                                <MdLogout color={theme === "light" ? "#000" : "#ADBDDB"} />
                                <span>{t("Log out")}</span>
                            </button>
                            <div>
                                <FaChevronRight color={theme === "light" ? "#000" : "#ADBDDB"} size={10} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4 className='text-black dark:text-[#ADBDDB] font-bold text-[12px]'>Copyright 2024 &copy; Roster Rocket</h4>
                        <p className='text-black dark:text-[#ADBDDB] font-light text-[10px]'>App Version 1.13</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar