import React from "react";
import { useTranslation } from 'react-i18next';

const Tabs = ({ tabName, setTabName, tabs }) => {
    const { t } = useTranslation()
    return (
        <div className="shadow-md flex py-2 rounded-lg mb-3 justify-around">
            {
                tabs.map((tab, i) => (

                    <button
                        key={i}
                        onClick={() => setTabName(tab.name)}
                        className={`py-1 transition-all w-fit text-center duration-500 px-3 ${tabName === tab.name ? " dark:bg-[#2E4682]/20 bg-[#E2EAFF]" : "text-black dark:text-white "
                            }  rounded-lg`}
                    >
                        <h4
                            className={`${tabName === tab.name ? ` ${tab.color} ` : "text-black dark:text-white"
                                } text-[13px] font-bold rounded-md transition-all duration-500 `}
                        >
                            {t(tab.name)}
                        </h4>
                    </button>
                ))
            }
        </div>
    );
};

export default Tabs;
