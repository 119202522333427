import React, { useEffect, useState } from "react";
import { GoStopwatch } from "react-icons/go";
import { MdOutlineDateRange } from "react-icons/md";
import { FaDropbox } from "react-icons/fa";
import { FaUserGear } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbClockOff } from "react-icons/tb";
const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabName, setTabName] = useState("/");
  useEffect(() => {
    setTabName(location.pathname);
  }, [location.pathname]);
  return (
    <div className="py-3 dark:bg-[#434D83] rounded-tl-lg rounded-tr-lg px-4 justify-between bg-gray-200 w-full flex relative  ">
      <div className="flex gap-12">
        <button onClick={() => navigate("/Schedule")}>
          <GoStopwatch
            size={20}
            className={
              tabName === "/Schedule"
                ? "text-[#540ae6] dark:text-gray-400"
                : "text-gray-500 dark:text-[#ffffff]"
            }
          />
        </button>
        <button onClick={() => navigate("/events")}>
          <TbClockOff
            size={20}
            className={
              tabName === "/events"
                ? "text-[#540ae6] dark:text-gray-400"
                : "text-gray-500 dark:text-[#ffffff]"
            }
          />
        </button>
      </div>
      <button
        onClick={() => navigate("/")}
        className="bg-[#540ae6] p-3 rounded-full absolute left-[45%] -top-4">
        <MdOutlineDateRange size={20} color="white" />
      </button>
      <div className="flex gap-12">
        <button onClick={() => navigate("/dropout")}>
          <FaDropbox
            size={20}
            className={
              tabName === "/dropout"
                ? "text-[#540ae6] dark:text-gray-400"
                : "text-gray-500 dark:text-[#ffffff]"
            }
          />
        </button>
        <button onClick={() => navigate("/profile")}>
          <FaUserGear
            size={20}
            className={
              tabName === "/profile"
                ? "text-[#540ae6] dark:text-gray-400"
                : "text-gray-500 dark:text-[#ffffff]"
            }
          />
        </button>
      </div>
    </div>
  );
};

export default Footer;
