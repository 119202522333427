import React, { useEffect, useState } from "react";
import ViewTabs from "../components/date/ViewTabs";
import CalenderComp from "../components/date/CalenderComp";
import 'react-calendar/dist/Calendar.css';
import { getEmployeeHolidayIllnessesAPI, getEmployeeShiftplansAPI, getNationalHolidaysEmployeeAPI } from "../lib/apis";
import { getFirstAndLastDayOfMonth } from "../lib/getFirstAndLastDate";
import { format } from 'date-fns';
import ListView from "../components/date/ListView";
import DateDetails from "../components/date/DateDetails";

const getCurrentMonthDates = () => {
  const currentDate = new Date();
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  // Format dates to "YYYY-MM-DD" format
  const formatToISODate = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return { start: formatToISODate(startOfMonth), end: formatToISODate(endOfMonth) };
};


const DatePage = () => {
  const [tabName, setTabName] = useState("Calender View");
  const [date, setDate] = useState(getFirstAndLastDayOfMonth());

  const [employeeShifts, setEmployeeShifts] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [dateDetails, setDateDetails] = useState(null)
  const [nationalHolidays, setNationalHolidays] = useState()
  const [holidayIllness, setHolidayIllness] = useState([])
  const [months, setMonths] = useState(getCurrentMonthDates());
  useEffect(() => {
    setIsLoading(true)
    getEmployeeShiftplansAPI({
      mode: "mobile",
      start: date.start,
      end: date.end,
    }).then((res) => {

      const sortedRes = res.data.sort(
        (a, b) => new Date(a.startTime) - new Date(b.startTime)
      );
      setEmployeeShifts(sortedRes)
    })
      .catch((err) => console.log(err));

    getEmployeeHolidayIllnessesAPI().then(res => {
      if (res.data.length) {
        let offTimeArray = []; // Initialize the array to store new off times

        // Assuming you're processing the first illness record as an example
        const illnessPeriod = res.data[0]; // This could be looped or mapped if processing multiple
        const startDate = new Date(illnessPeriod.startTime);
        const endDate = new Date(illnessPeriod.endTime);

        function addDays(date, days) {
          const result = new Date(date);
          result.setDate(result.getDate() + days);
          return result;
        }

        for (let date = startDate; date <= endDate; date = addDays(date, 1)) {
          const newItem = {
            startTime: date,
            endTime: endDate,
            name:
              illnessPeriod.employee.firstName +
              " " +
              illnessPeriod.employee.lastName,
            type: illnessPeriod.type,
            mode_offtime: true,
          };
          offTimeArray.push(newItem); // Add the new item to the array
        }

        // Update state with new items
      }
      const sortedRes = res.data.sort(
        (a, b) => new Date(a.startTime) - new Date(b.startTime)
      );
      setHolidayIllness(sortedRes)

      const datesShift = sortedRes.map(dates => (new Date(dates.startTime)))
      // setHolidayIllness(datesShift)
    }).catch((err) => console.log(err));

    getNationalHolidaysEmployeeAPI({ ...months }).then(res => {
      const eventData = res.data.map((event) => {
        const startTime = format(new Date(event.startDate), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"); // Format date using date-fns
        return {
          startTime: startTime,
          endTime: startTime,
          name:
            event?.name?.find((obj) => obj?.language === "EN")?.text ||
            "Holiday", // Default title if not found,
          type: "national",
        };
      });

      const uniqueEventData = [];
      const seenNames = new Set();

      eventData.forEach((event) => {
        if (!seenNames.has(event.name)) {
          seenNames.add(event.name);
          uniqueEventData.push(event);
        }
      });
      // console.log(uniqueEventData, "uniqueEventData")
      setNationalHolidays(res.data)
      setIsLoading(false)
    }).catch((err) => console.log(err)).finally(() => setIsLoading(false));

  }, [date])



  return (
    <div className="flex flex-col gap-4 max-h-[calc(100vh-120px)] overflow-y-auto overflow-x-hidden">
      <ViewTabs tabName={tabName} setTabName={setTabName} />
      {
        tabName === "Calender View" ? (
          <>
            <CalenderComp setMonth={setMonths} month={months} setDate={setDate} tabName={tabName} date={date} employeeShifts={employeeShifts} setDateDetails={setDateDetails} holidayIllness={holidayIllness} nationalHolidays={nationalHolidays} />
          </>
        ) : (
          <ListView employeeShifts={employeeShifts} setDateDetails={setDateDetails} holidayIllness={holidayIllness} nationalHolidays={nationalHolidays} />
        )
      }
      {
        isLoading ? (
          <div className='w-full h-full flex justify-center items-center'>
            <img src='/images/loader2.svg' className='w-20' />
          </div>
        ) : (

          <DateDetails dateDetails={dateDetails} />
        )
      }
    </div>
  );
};

export default DatePage;
