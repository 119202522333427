import React, { useState } from "react";
import { MdOutlineEmail } from "react-icons/md";
import { CiLock } from "react-icons/ci";
import { FaRegEyeSlash } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAppContext } from "../components/context/Context";
import { setToken } from "../lib/localstorage";
import { signInAPI } from "../lib/apis";
const SignIn = () => {
  const isAuthenticated = localStorage.getItem("access_token");
  const { setUserInfo } = useAppContext()
  const [errorMsg, setErrorMsg] = useState("")
  const [showPass, setShowPass] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrorMsg("")
      setIsLoading(true)
      const res = await signInAPI({
        userInfo: data.email,
        password: data.password,
        mode: "mobile"
      });
      setUserInfo(res.data)
      localStorage.setItem("user", JSON.stringify(res.data))
      setToken(res.data?.body?.token);
      setIsLoading(false)
      navigate("/");
    } catch (error) {
      console.log(error)
      setErrorMsg(error?.response?.data?.message || "error while logging in")
      setIsLoading(false)
    }
  };

  return isAuthenticated ? (
    <Navigate to="/" />
  ) : (
    <div className="min-h-screen bg-white flex flex-col relative">
      <div className="absolute bottom-0 left-0">
        <img className="w-20" src="/images/Vector1.svg" alt="" />
      </div>
      <div className="absolute right-0">
        <img className="w-20" src="/images/Vector2.svg" alt="" />
      </div>
      <div className="flex-1 bg-white w-full  flex flex-col items-center justify-center gap-5">
        <div className="">
          <img className="w-40" src="/images/roster_logo.png" alt="" />
        </div>
        <div className="flex flex-col">
          <p className="text-[#540ae6] text-xl font-bold">
            Welcome to RosterRocket
          </p>
          <p className="text-[#540ae6] text-xl font-bold">
            --Your Digital shift plan--
          </p>
        </div>
      </div>
      <div className="flex-1 flex w-full  items-center">
        {/* #d2d1d3 */}
        <form onSubmit={handleSubmit} className="flex w-full px-4 mb-6 flex-col gap-3">
          <div className="flex flex-col gap-7">
            <div className="flex gap-5 py-4 px-3 rounded-lg items-center bg-gray-300">
              <div>
                <MdOutlineEmail color="gray" size={20} />
              </div>
              <input
                type="text"
                className="bg-gray-300 w-full outline-none text-gray-600 font-semibold"
                placeholder="Enter Email"
                name="email"
                required
                value={data.email}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="flex gap-5 py-4 px-3 rounded-lg items-center bg-gray-300">
              <div>
                <CiLock color="gray" size={20} />
              </div>
              <input
                type={showPass ? "text" : "password"}
                className="bg-gray-300 w-full outline-none text-gray-600 font-semibold"
                placeholder=" Password"
                name="password"
                required
                value={data.password}
                onChange={(e) => handleChange(e)}
              />
              <button type="button" onClick={() => setShowPass(!showPass)}>
                {
                  showPass ? (
                    <IoEyeOutline color="gray" size={20} />
                  ) : (

                    <FaRegEyeSlash color="gray" size={20} />
                  )
                }
              </button>
            </div>
          </div>
          <div className="flex justify-end">
            <Link

              to="/forgotPassword"
              className="text-[16px] font-bold text-slate-900 underline"
            >
              Forgot Password?
            </Link>
          </div>
          <p className="text-red-700">{errorMsg && errorMsg}</p>
          <button type="submit"
            disabled={isLoading} className={` ${isLoading ? "bg-[#3a0970]" : "bg-[#540ae6]"}  rounded-lg py-4 px-3 text-white text-[16px] w-full shadow-2xl text-center`}>
            {isLoading ? "Signing in..." : "SIGN IN"}

          </button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
